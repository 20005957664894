import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export function WP_ChannelMissions({title, channelMissions, setDisplayCreateMission, clientId, missions, ypIsOwner, ypIsAdmin, ypCreateMissions, setViewmiss_fromMain}) {
    const [viewAvailable, setViewAvailable] = useState(true);
    const [viewCurrent, setViewCurrent] = useState(true);
    const [viewFinished, setViewFinished] = useState(false);
    const [viewableMissions, setViewableMissions] = useState([]);

    const navigate = useNavigate();

    // Calculate viewables again if missions change
    useEffect(() => {
        if(!channelMissions || channelMissions.length === 0) {
            setViewableMissions([]);
            return;
        }

        let rawMissions = [];
        for(let i = 0; i < channelMissions.length; i++) {
            const m = channelMissions[i];
            let add = false;

            if(!add && viewAvailable && !m.complete && !missions.includes(m.id)) {
                add = true;
            }

            if(!add && viewCurrent && missions.includes(m.id) && !m.complete) {
                add = true;
            }

            if(!add && viewFinished && m.complete) {
                add = true;
            }

            if(add) {
                rawMissions.push(m);
            }
        }

        setViewableMissions(rawMissions);
    }, [viewAvailable, viewCurrent, viewFinished]);

    return (
        <div className='wirePanel __cm_content'>
            <h2 className='wirePanel_h2'>
                {title}
            </h2>

            <div className='d-flex'>
                <div className='d-flex flex-column'>
                    <div className='__cm_list_header d-flex'>
                        <div className='__chv_item_3'>Mission</div>
                        <div className='__chv_item_2'>Level</div>
                        <div className='__chv_item_2'>Members</div>
                    </div>
                    <div className='__cm_list'>
                        <div className='d-flex flex-column'>
                            {viewableMissions.map((x) => <MissionItem key={`MISS${x.id}`} {...{...x, navigate, setViewmiss_fromMain, missions}}/>)}
                        </div>
                    </div>
                </div>

                <div className='__cm_options d-flex flex-column justify-content-between'>
                    <div className='mt-5'>
                        <div className='d-flex justify-content-around gap-1'>
                            <label className='__cm_options_label'>View Available:</label>
                            <div className={`altCheckbox ${viewAvailable ? 'altCheckbox_selected' : ''}`} onClick={(e) => {
                                e.preventDefault();
                                setViewAvailable(!viewAvailable);
                            }}/>
                        </div>
                        <div className='d-flex justify-content-around gap-1'>
                            <label className='__cm_options_label'>View Current:</label>
                            <div className={`altCheckbox ${viewCurrent ? 'altCheckbox_selected' : ''}`} onClick={(e) => {
                                e.preventDefault();
                                setViewCurrent(!viewCurrent);
                            }}/>
                        </div>
                        <div className='d-flex justify-content-around gap-1'>
                            <label className='__cm_options_label'>View Completed:</label>
                            <div className={`altCheckbox ${viewFinished ? 'altCheckbox_selected' : ''}`} onClick={(e) => {
                                e.preventDefault();
                                setViewFinished(!viewFinished);
                            }}/>
                        </div>
                    </div>
                    
                    
                    <div className='d-flex flex-column mb-4'>
                        {
                            ypIsOwner || ypIsAdmin || ypCreateMissions ? 
                            <button className='__cm_btn mx-auto crtext'
                            onClick={() => {
                                setDisplayCreateMission(true);
                            }}>
                                Create Mission
                            </button>
                            :
                            undefined
                        }
                    </div>                 
                </div>
            </div>
        </div>
    );
}

const MissionItem = ({id, name, recLevel, members, maxCapacity, complete, navigate, setViewmiss_fromMain, missions}) => {
    return (
        <button className={`__cm_mission_item crtext d-flex ${complete ? '__cm_mission_item__complete' : missions.includes(id) ? '__cm_mission_item__current' : ''}`} onClick={((e) => {
            e.preventDefault();
            setViewmiss_fromMain(false);
            navigate(`/app/mission/${id}`);
        })}>
            <div className='__chv_item_3'>{name}</div>
            <div className='__chv_item_2'>{recLevel}</div>
            <div className='__chv_item_2'>{members.length - 1}{maxCapacity && maxCapacity > 0 ? `/${maxCapacity}` : undefined}</div> {/* -1 to account for owner */}
        </button>
    );
}
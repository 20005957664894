import React, { useState, useEffect, useContext } from 'react';


export function WP_Info({ title, visChannels, setVisChannels, ChannelSortType, viewPublic, sortType, setReverseSort, reverseSort, viewAnyJoin, viewInviteOnly, textQuery, setParamFromValues, setSearchParams, setViewPublic, setViewAnyJoin, setViewInviteOnly, setSortType, setTextQuery, StartSearch }) {
    return (
        <div className='wirePanel __i_content'>
            <h2 className='wirePanel_h2'>
                {title}
            </h2>

            <div className='__i_internal d-flex flex-column px-3 justify-content-between'>
                <div>
                    <div className='__i_subpanel_vpc d-flex mt-4 justify-content-between'>
                        <label className='__i_subpanel_vpc_label'>View Public Channels:</label>
                        <div className={`baseCheckbox ${viewPublic ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                            setViewPublic(!viewPublic);
                        }}/>
                    </div>
                    {viewPublic ?
                    <div className='__i_subpanel'>
                        <div className='d-flex justify-content-between __i_subpanel_content'>
                            <label>View Joinable:</label>
                            <div className={`baseCheckbox ${viewAnyJoin ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                                setViewAnyJoin(!viewAnyJoin);
                            }}/>
                        </div>
                        <div className='d-flex justify-content-between __i_subpanel_content'>
                            <label>View Invite Only:</label>
                            <div className={`baseCheckbox ${viewInviteOnly ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                                setViewInviteOnly(!viewInviteOnly);
                            }}/>
                        </div>
                    </div> : undefined}
                    
                    <div className='d-flex mt-3 flex-column __i_subpanel_content'>
                        <label>Sort:</label>
                        <div className='d-flex justify-content-between gap-3'>
                            <div className='custom-select'>
                                <select
                                value={sortType} 
                                onChange={(e) => {setSortType(e.target.value)}}>
                                    <optgroup>
                                    {ChannelSortType.map((x) => {
                                        return <option key={x} value={x}>{x}</option>
                                    })}
                                    </optgroup>
                                </select>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <label>Reverse:</label>
                                <div className={`baseCheckbox ${reverseSort ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                                    setReverseSort(!reverseSort);
                                }}/>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-column __i_subpanel_content mt-3'>
                        <label>Search:</label>
                        <input className='__i_search_input' type="text" value={textQuery} onChange={(e) => setTextQuery(e.target.value)}/>
                    </div>
                </div>
                
                

                <div className='d-flex justify-content-center mt-5'>
                    <button className='__i_search_btn crtext' onClick={() => {
                        StartSearch(setParamFromValues());
                    }}>SEARCH</button>
                </div>
            </div>
        </div>
    );
}
import axios from 'axios';

const url = 'https://lelrgdxt20.execute-api.us-west-1.amazonaws.com/api/'
////////////////////// https://an722z5snj.execute-api.us-west-1.amazonaws.com/default/

const getClientInfo = async (token) => {
    // const options = {
    //     method: 'GET',
    //     headers: {
    //         'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
    //         'Access-Control-Allow-Methods': '*',
    //         'Access-Control-Allow-Origin': '*',
    //         'Content-Type': 'text/html',
    //         // 'Content-Type': 'application/json',
    //         'Authorization': token,
            
    //     },
    //     // body: JSON.stringify({
    //     //     empty: 'nothing here!'
    //     // })
    // };

    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token
    }
    
    return await new Promise((res, rej) => {
        axios.get(url + 'pof_GetStarterInfo', { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const UpdateHandlerName = async (token, newName) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify({handlerName: newName});

    return await new Promise((res, rej) => {
        axios.put(url + 'pof_setHandler', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const UploadPilot = async (token, rawPilot) => {
    // Assume rawPilot is now an object
    const mechSkills = rawPilot.mechSkills;
    const hull = mechSkills[0];
    const agility = mechSkills[1];
    const systems = mechSkills[2];
    const engineering = mechSkills[3];

    const ConvertedPilot = {
        id: rawPilot.id,
        callsign: rawPilot.callsign,
        name: rawPilot.name,
        status: rawPilot.status,
        level: rawPilot.level,
        portraitLink: rawPilot.cloud_portrait,
        mechSkills: {
            hull,
            agility,
            systems,
            engineering,
        }
    }

    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify(ConvertedPilot);
    return await new Promise((res, rej) => {
        axios.post(url + 'pof_uploadPilot', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const DeletePilot = async (token, pilotId) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify({ kill: pilotId });

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_deletePilot', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const CreateChannel = async (token, channelData) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify(channelData);

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_createChannel', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const UpdateChannel = async (token, channelData, channelId) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify({
        channelId,
        updateChannelData: channelData,
    });

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_updateChannel', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const GetChannels = async (token, queryOptions) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
    }

    const keys = Object.keys(queryOptions);
    const rawValues = keys.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(queryOptions[key]));
    const queryParams = rawValues.join('&');

    const fullGETUrl = `${url}pof_getChannels?${queryParams}`;

    return await new Promise((res, rej) => {
        axios.get(fullGETUrl, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const GetChannel = async (token, id) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
    }

    const fullGETUrl = `${url}pof_getOneChannel/?id=${id}`;

    return await new Promise((res, rej) => {
        axios.get(fullGETUrl, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const CreateMission = async (token, missionData) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify(missionData);

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_createMission', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const UpdateMission = async (token, missionData) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify(missionData);

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_updateMission', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const GetMission = async (token, id) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
    }

    const fullGETUrl = `${url}pof_getOneMission/?id=${id}`;

    return await new Promise((res, rej) => {
        axios.get(fullGETUrl, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const JoinMission = async (token, joininfo) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify(joininfo);

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_joinMission', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const LeaveMission = async (token, leaveInfo) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify(leaveInfo);

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_leaveMission', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const CompleteMission = async (token, missionId) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify({missionId});

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_completeMission', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const JoinChannel = async (token, joininfo) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify(joininfo);

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_joinChannel', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const SaveMemberPerms = async (token, requestinfo) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    const data = JSON.stringify(requestinfo);

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_saveMemberPerms', data, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const RequestNewInviteKey = async (token, channelId) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }


    return await new Promise((res, rej) => {
        axios.post(url + 'pof_reqNewKey', {channelId}, { headers }).then((response) => {
            res(response.data);
        }).catch((err) => {
            rej(err);
        })
    });
}

const RequestDiscordConnect = async (token, code) => {
    const headers = {
        'Access-Control-Allow-Headers': 'Authorization,*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: token,
        'Content-Type': 'application/json'
    }

    return await new Promise((res, rej) => {
        axios.post(url + 'pof_ConnectDiscord', {code}, { headers }).then((response) => {
            console.log("GOT RESPONSE: ", response);
            res(response.data);
        }).catch((err) => {
            rej(err);
            console.log("GOT ERROR: ", err.response);
        })
    });
}

export {
    getClientInfo,
    UpdateHandlerName,
    UploadPilot,
    DeletePilot,
    CreateChannel,
    UpdateChannel,
    GetChannels,
    GetChannel,
    CreateMission,
    UpdateMission,
    GetMission,
    JoinMission,
    LeaveMission,
    CompleteMission,
    JoinChannel,
    SaveMemberPerms,
    RequestNewInviteKey,
    RequestDiscordConnect
}
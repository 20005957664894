import React from 'react';
import { useNavigate } from 'react-router-dom';

import './splashheader.css';

export function SplashHeader(props) {
    const navigate = useNavigate();

    return (
        <div className={`sp_header${props.solid ? '_solid' : ''} d-flex flex-column`}>
            <div className='d-flex justify-content-between' id='sp_header_bg'>
                <img id='sp_header_logo' src='Other/AgriaLogo.png'/>
                <div className='d-flex'>
                    <button className='sp_header_nav sp_noright' onClick={() => {navigate('/')}}>HOME</button>
                    <button className='sp_header_nav' onClick={() => {navigate('/about')}}>ABOUT</button>
                </div>
            </div>
            <div id='sp_extra_border'/>
        </div>
    );
}
import React, { useState } from 'react';
import { ChannelOption } from './ChannelOption';
import { useNavigate } from 'react-router-dom';

export function WP_Channels({title, visChannels, ChannelSortType, chosenOp, setChosenOp, setViewChosen, selectOption}) {
    const navigate = useNavigate();

    return (
        <div className='wirePanel __c_content'>
            <h2 className='wirePanel_h2'>
                {title}
            </h2>

            <div className='__c_internal d-flex flex-column px-1'>
                <div className='__c_tablehead crtext d-flex'>
                    <div className='__c_item_0'/>
                    <div className='__c_item_3'>id</div>
                    <div className='__c_item_4'>Name</div>
                    <div className='__c_item_3'>Privacy</div>
                    <div className='__c_item_3'>Members</div>
                    <div className='__c_item_2'>Affiliation</div>
                </div>
                <div className='__c_pages d-flex flex-column'>
                    {visChannels ? 
                    (
                        visChannels.length > 0 ? visChannels.map((x) => 
                        <ChannelOption
                            id={x.id}
                            key={x.id}
                            channel={x}
                            chosen={chosenOp}
                            selectOption={selectOption}
                        />)
                        :
                        <h3>No channels...</h3>
                    )
                     : <h3>Loading...</h3>}
                </div>
            </div>
        </div>
    );
}
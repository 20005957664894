import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const DEFAULT_IMG = '/Icons/ico_channels.svg';

export function WP_ChannelOptions({ title, pubVisible, setPubVisible, anyJoin, setAnyJoin, allowApply, setAllowApply, imgUrl, setImgUrl }) {
    // start off in the loading state
    const [useCustomImg, setUseCustomImg] = useState(false);


    return (
        <div className='wirePanel __co_content'>
            <h2 className='wirePanel_h2'>
                {title}
            </h2>

            <form className='mx-3 d-flex flex-column gap-3'>
                <div className='channel_c_checkbox_container d-flex justify-content-between'>
                    <label>Visible to Public:</label>
                    <div className={`baseCheckbox ${pubVisible ? 'baseCheckbox_selected' : ''}`} onClick={() => setPubVisible(!pubVisible)}/>
                </div>
                <div className='channel_c_checkbox_container d-flex justify-content-between'>
                    <label>Allow Any Join:</label>
                    <div className={`baseCheckbox ${anyJoin ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                        if(!anyJoin) setAllowApply(false);
                        setAnyJoin(!anyJoin)
                    }}/>
                </div>
                <div className='channel_c_checkbox_container d-flex justify-content-between'>
                    <label>Allow Applications:</label>
                    <div className={`baseCheckbox ${allowApply ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                        if(!allowApply) setAnyJoin(false);
                        setAllowApply(!allowApply)
                    }}/>
                </div>

                <div className='channel_c_img_container d-flex flex-column'>
                    <label>Icon Url:</label>
                    <div>
                        <input className='channel_c_img_input crtext' type="text" value={imgUrl} onChange={(e) => setImgUrl(e.target.value)}/>
                        <button className='channel_c_img_button crtext' onClick={(e) => {
                            e.preventDefault();
                            setUseCustomImg(true);
                        }}>{'>> Use'}</button>
                    </div>
                    <img className={(useCustomImg) ? 'channel_c_img_img' : 'channel_c_img_default'} src={(useCustomImg) ? imgUrl : DEFAULT_IMG} onError={() => {
                        setUseCustomImg(false);
                        toast.error("Invalid image url.");
                    }}/>
                </div>
            </form>
        </div>
    );
}
import React from 'react';
import { useNavigate } from 'react-router-dom';

import './splashfooter.css';

export function SplashFooter() {
    return (
        <div id='sp_footer'>
            <div className='d-flex justify-content-around mt-4'>
                <div className='d-flex flex-column'>
                    <h3 className='sp_h3'>Attribution</h3>
                    <a className='sp_a' href="https://www.sesohq.com/">Aquire Font</a> 
                    <a className='sp_a' href="http://aleclownes.com/2017/02/01/crt-display.html">CRT Filter</a> 
                    <a className='sp_a' href="https://www.svgrepo.com/collection/sharp-filled-action-icons/">SVG Icons</a> 
                </div>

                <div className='d-flex flex-column'>
                    <h3 className='sp_h3'>Stock Photos By</h3>
                    <span>Zen Chung</span>
                    <span>ZCH</span>
                    <span>Pixabay</span>
                    <span>ljubaphoto</span>
                </div>
            </div>
        </div>
    );
}
import React, { useState } from 'react';

import { WP_MailMenu } from './WP_MailMenu';
import { WP_Inbox } from './WP_Inbox';
import { BackButton } from '../../Shared/Backbutton';

import './mail.css';


export function Mail(props) {
  
      return (
        <div className='mail_content d-flex justify-content-around'>
            <BackButton/>
            <WP_MailMenu
                title={'// Menu'}
            />
            
            <WP_Inbox
                title={'// Mail'}
            />
        </div>
      );
}
import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import { RequestDiscordConnect } from '../../../Util/api';
import { AccountContext } from '../../../Account';


const headers = {
    // 'Access-Control-Allow-Headers': 'Authorization,*',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': '*',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json'
}

export function DISCORD_AUTH(props) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { getSessionThen } = useContext(AccountContext);
    
    useEffect(() => {
        const code = searchParams.get('code');
        if(!code) {
            console.log('Code not found');
            return;
        }

        getSessionThen((session) => {
            RequestDiscordConnect(session.getIdToken().getJwtToken(), code).then((data) => {
                toast.success("Connected to discord!");
                navigate('/app/profile');
            }).catch((err) => {
                toast.error("Failed to connect to discord...");
            })
        });
    }, []);

    return (
        <h1 className='crtext text-center mt-5'>You are being redirected. Please wait...</h1>
    );
}
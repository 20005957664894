import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AccountContext } from '../../../../Account';
import { BackButton } from '../../../Shared/Backbutton';
import { GetChannel, RequestNewInviteKey, SaveMemberPerms, UpdateChannel } from '../../../../Util/api';
import { WP_ChannelMissions } from './WP_ChannelMissions';
import { WP_ChannelNews } from './WP_ChannelNews';
import { CreateMissionPanel } from '../../../Shared/CreateMissionPanel';
import { MBCHM_Missions } from './MBCHM_Missions';

import './chview.css';
import './mbchview.css';
import './infosubpage.css';


const LOREM_IPSUM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
const DEFAULT_IMG = '/Icons/ico_channels.svg';
const OFF_AFF_AGRIA = '/Other/AgriaLogo.png';
const OFF_AFF_POF = '/Other/POFSymbol.png';
const OFF_AFF_GLBL = 'Icons/ico_globe.svg';

export function CHView(props) {
    return (
        <Routes>
            <Route key={0} path=":id" element={<ChannelView {...props}/>} />
        </Routes>
    );
}

function ChannelView({backTo, clientId, missions, setMissions, setViewmiss_fromMain, MOBILE_MODE}) {
    const {id} = useParams();
    const { getSessionThen } = useContext(AccountContext);
    const [channelData, setChannelData] = useState(undefined);
    const [memDetails, setMemDetails] = useState([]);
    const [channelMissions, setChannelMissions] = useState([]);
    const [useCustomImg, setUseCustomImg] = useState(false);
    const [displayCreateMission, setDisplayCreateMission] = useState(false);
    const [selectedMember, setSelectedMember] = useState('');
    const [inviteKey, setInviteKey] = useState('');

    const [yourPerms, setYourPerms] = useState({});
    const [ypIsOwner, setypIsOwner] = useState(false);
    const [ypIsAdmin, setypIsAdmin] = useState(false);
    const [ypManageUsers, setypManageUsers] = useState(false);
    const [ypManageMissions, setypManageMissions] = useState(false);
    const [ypCreateMissions, setypCreateMissions] = useState(false);
    const [ypJoinMissions, setypJoinMissions] = useState(false);

    const [cName, setCName] = useState('');
    const [cDesc, setCDesc] = useState('');

    const [pubVisible, setPubVisible] = useState(false);
    const [anyJoin, setAnyJoin] = useState(false);
    const [allowApply, setAllowApply] = useState(false);
    const [imgUrl, setImgUrl] = useState('');

    const [requestCD, setRequestCD] = useState(false);

    // 0 About, 1 Members, 2 Actions,
    // 3 Manage Channel
    const [infoState, setInfoState] = useState(0);

    // Mobile state
    // 0 Missions, 1 News, 2 About, 3 Actions
    const [mbInfoState, setMbInfoState] = useState(0);

    useEffect(() => {
        if(channelData) {
            setUseCustomImg(!!channelData.imgURL);

            setCName(channelData.name);
            setCDesc(channelData.desc);
            setPubVisible(channelData.pubVisible);
            setAnyJoin(channelData.anyJoin);
            setAllowApply(channelData.allowApply);
            setImgUrl(channelData.imgURL);
        }
        else setUseCustomImg(false);
    }, [channelData])
    
    useEffect(() => {
        getSessionThen((session) => {
            GetChannel(session.getIdToken().getJwtToken(), id).then((data) => {
                setChannelData(data);
                setChannelMissions(data.missionDetails);
                setInviteKey(data.inviteKey);
                setMemDetails(data.memberDetails);
            }).catch((err) => {
                setChannelData(undefined);
            })
        });
    }, [id])

    useEffect(() => {
        if(!channelData || !clientId) return;

        try {
            const perms = channelData.members.find((x) => x.id === clientId).perms;
            setYourPerms(perms);
        }
        catch {
            setYourPerms({});
        }
    }, [clientId, channelData]);

    useEffect(() => {
        setypIsOwner(clientId && channelData && clientId === channelData.ownerId);
        setypIsAdmin(yourPerms && !!yourPerms.isAdmin);
        setypManageUsers(yourPerms && !!yourPerms.manageUsers);
        setypManageMissions(yourPerms && !!yourPerms.manageMissions);
        setypCreateMissions(yourPerms && !!yourPerms.createMissions);
        setypJoinMissions(yourPerms && !!yourPerms.joinMissions);
    }, [yourPerms])

    const GetSubpage = function() {
        switch(infoState) {
            case 0:
                return (
                <div className='chv_subpage_container chv_about_container'>
                    <img className={`chv_img ${(useCustomImg) ? 'chv_cimg crimg' : 'chv_dimg'}`} src={(useCustomImg) ? channelData.imgURL : DEFAULT_IMG} onError={() => {
                        setUseCustomImg(false);
                    }}/>
                    <h2 className='selectable'>Members: {channelData.members.length}</h2>
                    <p className='chv_about_desc selectable'>
                        {channelData.desc}
                    </p>
                </div>
                );
            case 1:
                return (
                <>
                <div className='chv_members_header d-flex'>
                    <span className='__chv_item_4'>Name</span> <span>Role</span>
                </div>
                <div className='chv_subpage_container chv_member_container d-flex flex-column'>
                    {memDetails.map((x, index) => <MemberItem key={`mem${x.id}`} {...{...x, memDetails, setMemDetails, index, channelId: channelData.id, setSelectedMember, expanded:selectedMember === x.id, getSessionThen,  ypIsOwner, ypIsAdmin, ypManageUsers, requestCD, setRequestCD}}/>)}
                </div>
                </>
                );
            case 2:
                return (
                    <div className='chv_subpage_container'>
                        Actions are WIP. This is stuff like leave the channel and whatever idk
                    </div>
                );
            
            case 3:
                return (
                    <div className='chv_subpage_container chv_manage_container d-flex flex-column justify-content-between'>
                        <div className='d-flex flex-column gap-2'>
                            <div className='d-flex gap-2'>
                                <label className='chv_input_label'>Name: </label>
                                <input className='chv_input crtext' type='text' value={cName} onChange={(e) => {setCName(e.target.value)}}/>
                            </div>
                            <div className='d-flex gap-2'>
                                <label className='chv_input_label'>Desc: </label>
                                <textarea className='chv_input_area crtext' type='textarea' value={cDesc} onChange={(e) => {setCDesc(e.target.value)}}/>
                            </div>
                            <div className='d-flex gap-2'>
                                <label className='chv_input_label'>Img URL: </label>
                                <input className='chv_input crtext' type='text' value={imgUrl} onChange={(e) => {setImgUrl(e.target.value)}}/>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex'>
                                    <label>Public Visible: </label>
                                    <div className={`my-auto baseCheckbox ${pubVisible ? 'baseCheckbox_selected' : ''}`} onClick={() => setPubVisible(!pubVisible)}/>
                                </div>
                                <div className='d-flex'>
                                    <label>Any Join: </label>
                                    <div className={`my-auto baseCheckbox ${anyJoin ? 'baseCheckbox_selected' : ''}`} onClick={() => {if(!anyJoin) setAllowApply(false); setAnyJoin(!anyJoin);}}/>
                                </div>
                                <div className='d-flex'>
                                    <label>Applications: </label>
                                    <div className={`my-auto baseCheckbox ${allowApply ? 'baseCheckbox_selected' : ''}`} onClick={() => {if(!allowApply) setAnyJoin(false); setAllowApply(!allowApply);}}/>
                                </div>
                            </div>

                            <button className='chv_update_btn crtext' onClick={(() => {
                                if(requestCD || !channelData) return;
                                getSessionThen((session) => {
                                    setRequestCD(true);

                                    const updateData = {
                                        name: cName,
                                        desc: cDesc,
                                        pubVisible: !!pubVisible,
                                        anyJoin: !!anyJoin,
                                        allowApply: !!allowApply,

                                        imgURL: imgUrl
                                    };

                                    UpdateChannel(session.getIdToken().getJwtToken(), updateData, channelData.id).then((data) => {
                                        setChannelData(data);
                                        toast.success("Channel updated.");
                                    }).catch((err) => {
                                        toast.error("Failed to update channel.");
                                    }).finally(() => {
                                        setRequestCD(false);
                                    })
                                });
                            })}>
                                UPDATE CHANNEL
                            </button>
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className='chv_subpage_container chv_manage_container d-flex flex-column justify-content-between'>
                        <div className='d-flex flex-column gap-2'>
                            <h3 className='selectable'>{`Key: ${inviteKey}`}</h3>
                            <div className='d-flex gap-4'>
                                <button className='chv_info_btn crtext' onClick={() => {
                                    if(requestCD) return;
                                    getSessionThen((session) => {
                                        setRequestCD(true);
                                        RequestNewInviteKey(session.getIdToken().getJwtToken(), channelData.id).then((data) => {
                                            setInviteKey(data);
                                            toast.success("New invite key generated.");     
                                        }).catch((err) => {
                                            toast.error("Failed to generate new invite key.");
                                        }).finally(() => {
                                            setRequestCD(false);
                                        })
                                    });
                                }}>Generate Key</button>
                                <button className='chv_info_btn crtext' onClick={() => {
                                    navigator.clipboard.writeText(inviteKey);
                                    toast.success("Copied key to clipboard.");
                                }}>Copy Key</button>
                            </div>
                        </div>
                    </div>
                );
            default:
                return undefined;
        }
    }

    const GetMBSubpage = function() {
        switch(mbInfoState) {
            case 0: // Missions
                return <MBCHM_Missions {...{channelMissions, clientId, missions, setViewmiss_fromMain}}/>
            
            case 1: // NEWS
                return;

            case 2: // ABOUT
                return (
                    <div className='mb_chv_about_container'>
                        <h2 className='selectable'>Members: {channelData.members.length}</h2>
                        <p className='mb_chv_about_desc selectable'>
                            {channelData.desc}
                        </p>
                    </div>
                );

            case 3: // ACTIONS
                return;

            default:
                return;
        }
    }

    return (
        MOBILE_MODE ?
        <div className='d-flex justify-content-center'>
            <div className='mb_chv'>
            {
                channelData ?
                <>
                    <div className='d-flex flex-column justify-content-center'>
                        <img className={`m-auto chv_img ${(useCustomImg) ? 'mb_chv_cimg crimg' : 'mb_chv_dimg'}`} src={(useCustomImg) ? channelData.imgURL : DEFAULT_IMG} onError={() => {
                            setUseCustomImg(false);
                        }}/>
                        <h1 className='mb_chv_title selectable m-auto'>{channelData.name}</h1>
                    </div>
                    
                    <div className='d-flex flex-column'>
                        <div className='d-flex'>
                            <button className={`mb_chv_tabbtn ${mbInfoState === 0 ? 'mb_chv_curtab' : ''}`} onClick={() => {
                                setMbInfoState(0);
                            }}>
                                Missions
                            </button>
                            <button className={`mb_chv_tabbtn ${mbInfoState === 1 ? 'mb_chv_curtab' : ''}`} onClick={() => {
                                setMbInfoState(1);
                            }}>
                                News
                            </button>
                            <button className={`mb_chv_tabbtn ${mbInfoState === 2 ? 'mb_chv_curtab' : ''}`} onClick={() => {
                                setMbInfoState(2);
                            }}>
                                About
                            </button>
                            <button className={`mb_chv_tabbtn ${mbInfoState === 3 ? 'mb_chv_curtab' : ''}`} onClick={() => {
                                setMbInfoState(3);
                            }}>
                                Actions
                            </button>
                        </div>

                        <div className='mb_chv_mobileview'>
                            {GetMBSubpage()}
                        </div>
                    </div>
                </>
                :
                undefined
            }
            </div>
        </div>
        :

        <div className='chv'>
            {displayCreateMission ? <CreateMissionPanel {...{mode: 'create', setDisplay: setDisplayCreateMission, getSessionThen, channelId: channelData.id, missions, setMissions}}/> : undefined}
            <BackButton backTo={'/app/channels'}/>
            {channelData ?
            <div className='d-flex gap-3'>
                <div className='chv_info d-flex flex-column justify-content-between'>
                    <div className='chv_info_container'>
                        <h1 className='selectable'>{channelData.name}</h1>
                    </div>
                    
                    {GetSubpage()}

                    <div>
                        <div className='d-flex flex-column gap-2'>
                            {
                                ypIsOwner || ypIsAdmin ? 
                                <div className='d-flex gap-2'>
                                    <div className='d-flex gap-2 chv_alignright'>
                                        <button className={`chv_info_admbtn crtext ${infoState === 3 ? 'chv_info_selectedbtn' : ''}`} onClick={() => {
                                            setInfoState(3);
                                        }}>Edit Channel</button>
                                    </div>
                                    <div className='d-flex gap-2 chv_alignright'>
                                        <button className={`chv_info_admbtn crtext ${infoState === 4 ? 'chv_info_selectedbtn' : ''}`} onClick={() => {
                                            setInfoState(4);
                                        }}>Manage Invites</button>
                                    </div>
                                </div>
                                :
                                undefined
                            }
                            
                            <div className='d-flex gap-2'>
                                <button className={`chv_info_btn crtext ${infoState === 0 ? 'chv_info_selectedbtn' : ''}`} onClick={() => {
                                    setInfoState(0);
                                }}>About</button>
                                <button className={`chv_info_btn crtext ${infoState === 1 ? 'chv_info_selectedbtn' : ''}`} onClick={() => {
                                    setInfoState(1);
                                }}>Members</button>
                                <button className={`chv_info_btn crtext ${infoState === 2 ? 'chv_info_selectedbtn' : ''}`} onClick={() => {
                                    setInfoState(2);
                                }}>Actions</button>
                            </div>
                        </div>
                    </div>
                </div>

                <WP_ChannelMissions {...{title: '// Missions', channelMissions, setDisplayCreateMission, clientId, missions, ypIsOwner, ypIsAdmin, ypCreateMissions, setViewmiss_fromMain}}/>
                <div className='d-flex flex-column justify-content-between'>
                    <div className='newsgraphic_container'>
                        <img className='newsgraphic' src='/Icons/ico_globe.svg' alt='News Graphic'/>
                    </div>
                    <WP_ChannelNews title={'// News'}/>
                </div>
            </div>
            : undefined}
        </div>
    );
}

// channelData.memberDetails --- owner is a boolean
const MemberItem = (props) => {
    const {memDetails, setMemDetails, index, channelId, id, handler, owner, setSelectedMember, expanded, perms, getSessionThen, ypIsOwner, ypIsAdmin, ypManageUsers, requestCD, setRequestCD} = props;
    const [isAdmin, setIsAdmin] = useState(false);
    const [manageUsers, setManageUsers] = useState(false);
    const [manageMissions, setManageMissions] = useState(false);
    const [createMissions, setCreateMissions] = useState(false);
    const [joinMissions, setJoinMissions] = useState(false);

    useEffect(() => {
        resetPerms(perms);
    }, [perms])

    const resetPerms = (p) => {
        if(!p) return;

        setIsAdmin(!!p.isAdmin);
        setManageUsers(!!p.manageUsers);
        setManageMissions(!!p.manageMissions);
        setCreateMissions(!!p.createMissions);
        setJoinMissions(!!p.joinMissions);
    }

    const savePerms = function() {
        if(requestCD) return;

        const permObj = {
            isAdmin,
            manageUsers,
            manageMissions,
            createMissions,
            joinMissions
        }

        const requestParams = {
            perms: permObj,
            memberId: id,
            channelId, 
        }

        getSessionThen((session) => {
            setRequestCD(true);

            SaveMemberPerms(session.getIdToken().getJwtToken(), requestParams).then((data) => {
                memDetails[index].perms = data;
                setMemDetails(memDetails);
                toast.success("Member permissions updated");
            }).catch((err) => {
                toast.error("Unable to update member permissions");
                resetPerms();
            }).finally(() => {
                setRequestCD(false);
            })
        });
    }

    return (
        <div className='chv_member'>
            <button className={`chv_members_item crtext d-flex ${expanded ? 'chv_members_item_expanded' : ''}`} onClick={() => {
                if(ypIsOwner || ypIsAdmin || ypManageUsers) {
                    if(expanded) setSelectedMember('');
                    else setSelectedMember(id);
                }
            }}>
                <span className='__chv_item_4'>{handler}</span> <span>{owner ? 'Owner' : 'Member'}</span>
            </button>
            {
                expanded ?
                <div className='chv_member_submenu d-flex'>
                    <div className='chv_members_permsbox'>
                        <h2>Permissions</h2>
                        <div className='d-flex flex-column'>
                            <div className='d-flex chv_permset justify-content-between'>
                                <label className='chv_label'>Admin:</label>
                                <div className={`my-auto baseCheckbox ${isAdmin ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                                    if(!ypIsOwner) return;
                                    setIsAdmin(!isAdmin);
                                }}/>
                            </div>
                            <div className='d-flex chv_permset justify-content-between'>
                                <label>Manage Users:</label>
                                <div className={`my-auto baseCheckbox ${manageUsers ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                                    setManageUsers(!manageUsers);
                                }}/>
                            </div>
                            <div className='d-flex chv_permset justify-content-between'>
                                <label>Manage Missions:</label>
                                <div className={`my-auto baseCheckbox ${manageMissions ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                                    setManageMissions(!manageMissions);
                                }}/>
                            </div>
                            <div className='d-flex chv_permset justify-content-between'>
                                <label>Create Missions:</label>
                                <div className={`my-auto baseCheckbox ${createMissions ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                                    setCreateMissions(!createMissions);
                                }}/>
                            </div>
                            <div className='d-flex chv_permset justify-content-between'>
                                <label>Join Missions:</label>
                                <div className={`my-auto baseCheckbox ${joinMissions ? 'baseCheckbox_selected' : ''}`} onClick={() => {
                                    setJoinMissions(!joinMissions);
                                }}/>
                            </div>
                        </div>
                    </div>

                    <div className='chv_buttonbox d-flex flex-column'>
                        <h2 className='chv_align_right'>Options</h2>
                        <button className='chv_mem_button crtext'>
                            {'>> Kick <?>'}
                        </button>
                        <button className='chv_mem_button crtext'>
                            {'>> Ban <?>'}
                        </button>
                        <button className='chv_mem_button crtext mt-4'
                        onClick={() => {
                            savePerms();
                        }}>
                            {'>> Save Permissions'}
                        </button>
                    </div>
                </div>
                :
                undefined
            }
        </div>
    );
}
import React, { useState } from 'react';

export function MBWP_News(props) {
    const { title } = props;

    return (
        <div className='wirePanel __mb_n_content'>
            <h2 className='mb_wirePanel_h2'>
                {title}
            </h2>
        </div>
    );
}
import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { HeaderGraphic } from './../Shared/HeaderGraphic';
import { PlusBG } from './../Shared/PlusBG';
import { Home } from "./Home/Home";
import { Channels } from './Channels/Channels';
import { About } from './About/About';
import { Mail } from './Mail/Mail';
import { Profile } from './Profile/Profile';
import { AccountContext } from '../../Account';
import { getClientInfo } from '../../Util/api';
import { MissionView } from './MIssionView/MissionView';
import { MobileToolbar } from '../Shared/MobileToolbar';
import { MobileHeader } from '../Shared/MobileHeader';

import './noise.css';
import './corestyle.css';

const thisRoute = '/app/'

const PofRoutes = (fullProps) => [
  {
    index: true,
    element: <Home
      {...fullProps}
    />
  },
  {
    path: `mail`,
    element: <Mail
      mail={fullProps.mail}
    />
  },
  {
    path: `channels/*`,
    element: <Channels
      {...fullProps}
    />
  },
  {
    path: 'profile/*',
    element: <Profile
      {...fullProps}
    />
  },

  {
    path: 'about',
    element: <About />
  },

  {
    path: 'mission/*',
    element: <MissionView 
      {...fullProps}
    />
  }
];

export function POFApp(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [sessionStatus, setSessionStatus] = useState(false);
    const [loading, setLoading] = useState(true);

    const [clientId, setClientId] = useState(undefined);
    const [handler, setHandler] = useState('UNKNOWN');
    const [channels, setChannels] = useState([]);
    const [missions, setMissions] = useState([]);
    const [pilots, setPilots] = useState([]);
    const [mail, setMail] = useState([]);
    const [homeMissions, setHomeMissions] = useState([]);

    // Misc
    const [viewmiss_fromMain, setViewmiss_fromMain] = useState(true);

    const { getSession } = useContext(AccountContext);
    const SetClientState = (client) => {
      setHandler(client.handlerName);
      if(client.id) setClientId(client.id);
      else toast.error("Got client data but missing ID");

      if(client.channels) setChannels(client.channels);
      if(client.missions) setMissions(client.missions);
      if(client.pilots) setPilots(client.pilots);
      if(client.mail) setMail(client.mail);
    }

    const UpdateClientData = ({onSuccess, onError}, init = false) => {
      getSession().then(session => {
          // This means getting the session worked, continue as normal
          getClientInfo(session.getIdToken().getJwtToken()).then((data) => {
          setSessionStatus(true);
          
          SetClientState(data.client);
          setHomeMissions(data.missions);

          // Invoke full success
          if(onSuccess) onSuccess(data);
        }).catch((err) => {
          if(onError) onError(err);
        });
      }).catch(() => {
        // This means getting the session didn't work... Go back home/force sign out
        if(init) {
          setSessionStatus(false);
          navigate('/');
        }
      })
    };

    const fullState = { clientId, handler, channels, missions, pilots, mail, setHandler, setChannels, setMissions, setPilots, setMail, SetClientState, homeMissions, viewmiss_fromMain, setViewmiss_fromMain, curWidth: props.curWidth, MOBILE_MODE: props.MOBILE_MODE, version: props.version };

    useEffect(() => {
      UpdateClientData({
        onSuccess: (data) => {
          setLoading(false);
          console.log("Successfully got initial client info: ", data);
        },
        onError: (err) => {
          console.log("Error getting initial client info: ", err.response);
          toast.error("Failed to retrieve initial client data.");
        }
    }, true)
    }, []);

    return (
    <div className='crt'>
        {props.MOBILE_MODE ? <MobileHeader version={props.version}/> : <HeaderGraphic location={location.pathname}/>}
        
        <Routes>
            {PofRoutes(fullState).map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
            })}
        </Routes>

        <PlusBG MOBILE_MODE={props.MOBILE_MODE}/>

        {
        props.MOBILE_MODE ? <MobileToolbar/>
        :
        <footer className='home_footer d-flex flex-column'>
           <div className='d-flex' id='company_container'>
            <img className='company_logo crimg' src='/Other/AgriaLogo.png' alt='Agria Logo'/>
            <img className='company_logo crimg' src='/Other/POFLogo.png' alt='POF Logo'/>
          </div>
          POF-NETWORK [{ props.version ? props.version : 'v0.2.0'}]
          {/* <button className='ui-btn' onClick={openMail}><img className='ui-img' src={`/Icons/ico_options.svg`} alt='News Graphic'/></button> */}
        </footer>
        }
        
      </div>
    );
}

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { WP_ChannelOptions } from './WP_ChannelOptions';
import { AccountContext } from '../../../../../Account';
import { CreateChannel } from '../../../../../Util/api';
import { BackButton } from '../../../../Shared/Backbutton';

import './channelcreate.css';



export function ChannelCreate(props) {
    const navigate = useNavigate();
    const [cName, setCName] = useState('');
    const [cDesc, setCDesc] = useState('');

    const [pubVisible, setPubVisible] = useState(false);
    const [anyJoin, setAnyJoin] = useState(false);
    const [allowApply, setAllowApply] = useState(false);
    const [imgUrl, setImgUrl] = useState('');

    const [requestCD, setRequestCD] = useState(false);

    const checkboxProps = { pubVisible, setPubVisible, anyJoin, setAnyJoin, allowApply, setAllowApply, imgUrl, setImgUrl };
    const { getSessionThen } = useContext(AccountContext);

    const requestCreateChannel = () => {
        if(requestCD) return;

        getSessionThen((session) => {
            setRequestCD(true);

            const channelData = {
                // id
                // ownerId
                // members
                // applications
                // news

                name: cName,
                desc: cDesc,

                pubVisible,
                anyJoin,
                allowApply,

                imgURL: imgUrl,

                // invite key
            };

            CreateChannel(session.getIdToken().getJwtToken(), channelData).then((data) => {
                toast.success("Channel created");
                navigate('/app/channels');
            }).catch((err) => {
                toast.error("Failed to create channel" + err.message);
            }).finally(() => {
                setRequestCD(false);
            })
        });
    }

    return (       
        <div className='channel_c_create'>
            <BackButton backTo={'/app/channels'}/>
            
            <div className='d-flex gap-5'>
                <form className='channel_c_form' onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <h1>Create Channel</h1>
                    <div className='d-flex flex-column'>
                        <label>Channel Name:</label>
                        <input className='channel_c_form_input channel_c_form_title_input crtext' type="text" value={cName} onChange={(e) => setCName(e.target.value)}/>

                        <label className='mt-3'>Description:</label>
                        <textarea className='channel_c_form_tarea crt' value={cDesc} onChange={(e) => setCDesc(e.target.value)}/>
                    </div>

                    <button className='textbutton mt-5 crtext' onClick={(event) => {
                        event.preventDefault();
                        requestCreateChannel();
                    }}>{'>> Create'}</button>        
                </form>
                <WP_ChannelOptions
                    title={'Channel Options'}
                    {...checkboxProps}
                /> 
            </div>
            
        </div>
    );
}
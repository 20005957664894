import React, { useState, useEffect } from 'react';

import './pilotview.css';

const PILOT_IMG_PATH = '/Other/EmptyAvatar.png';
const BARCODE_IMG_PATH ='Other/barcode.png';

// This is just an example
// const OurPilotClass = {
//     id: 'pilot.id',
//     callsign: 'pilot.callsign',
//     name: 'pilot.name',
//     status: 'pilot.status',
//     level: 'pilot.level',
//     portraitLink: 'pilot.cloud_portrait',
//     mechSkills: {
//         hull: 0,
//         agility: 0,
//         systems: 0,
//         engineering: 0,
//     }
// }

export function PilotView({callback, pilot_ob, clickable, MOBILE_MODE}) {
    const [curPilot, setCurPilot] = useState(undefined);
    const [portraitLink, setPortraitLink] = useState(PILOT_IMG_PATH);

    useEffect(() => {
        setPortraitLink(pilot_ob.portraitLink && pilot_ob.portraitLink !== '' ? pilot_ob.portraitLink : PILOT_IMG_PATH);
        setCurPilot(pilot_ob);
    }, [pilot_ob])

    if(curPilot === undefined || curPilot === null) return;

    return (
        MOBILE_MODE ?
        <div className={`p-3 mb_pilotview_content ${clickable ? 'pilotview_content_btn' : ''}`} onClick={(e) => {
            e.preventDefault();
            if(callback) callback(pilot_ob);
        }}>
            <div className='d-flex'>
                <div className='d-flex flex-column'>
                    <img className='mb_pilot_img image_mask crimg mx-auto' src={portraitLink} alt='Pilot Profile'/>
                    <h2 className='mb_info_text'>HUL: {curPilot.mechSkills.hull}</h2>
                    <h2 className='mb_info_text'>SYS: {curPilot.mechSkills.systems}</h2>
                    <h2 className='mb_info_text'>AGI: {curPilot.mechSkills.agility}</h2>
                    <h2 className='mb_info_text'>ENG: {curPilot.mechSkills.engineering}</h2>
                </div>
                
                <div className='d-flex flex-column'>
                    <h1 className='mt-1 mb_name_text'>{curPilot.name}</h1>
                    <h2 className='mb_info_text'>Callsign: {curPilot.callsign}</h2>
                    <h2 className='mb_info_text'>Official Status: {curPilot.status}</h2>
                </div>

                <h2 className='mb_lvltext'>LL: {curPilot.level}</h2>
            </div>
        </div>
        :

        <div className={`d-flex p-3 pilotview_content ${clickable ? 'pilotview_content_btn' : ''}`} onClick={(e) => {
            e.preventDefault();
            if(callback) callback(pilot_ob);
        }}>
            <div className='d-flex flex-column justify-content-between pilotview_pfphalf'>
                <img className='pilot_img image_mask crimg mx-auto' src={portraitLink} alt='Pilot Profile'/>
                <img className='barcode mx-auto' src={BARCODE_IMG_PATH} alt='Barcode'/>
                <p className='id_text mx-auto'>ID: {curPilot.id}</p>
            </div>
            
            <div className='vertline mx-2'/>
            
            <div className='d-flex flex-column gap-4'>
                <div className='d-flex justify-content-between'>
                    <div className='pilot_info_col'>
                        <h2 className='pilot_title_text'>Callsign: {curPilot.callsign}</h2>
                        <h2 className='pilot_info_text'>Name: {curPilot.name}</h2>
                        <h2 className='pilot_info_text'>Official Status: {curPilot.status}</h2>
                        
                    </div>
                    <div className='pilot_info_col'>
                        <h2 className='pilot_info_text_license'>License Level: {curPilot.level}</h2>
                        <RankImg level={curPilot.level}/>
                    </div>
                </div>

                <div className='d-flex flex-column'>
                <h2 className='pilot_title_text'>Mech Skills</h2>
                    <div className='d-flex justify-content-between pilot_info_container gap-5'>
                        <div className='pilot_info_col'>
                            <div className='d-flex flex-column'>
                                <h2 className='pilot_stat_text'> Hull: </h2>
                                <HexStats points={curPilot.mechSkills ? curPilot.mechSkills.hull : 0} skill='hul' />
                            </div>
                            <div className='d-flex flex-column'>
                                <h2 className='pilot_stat_text'> Agility: </h2>
                                <HexStats points={curPilot.mechSkills ? curPilot.mechSkills.agility : 0} skill='agi' />
                            </div>
                        
                    </div>
                    <div className='pilot_info_col'>
                        <div className='d-flex flex-column'>
                            <h2 className='pilot_stat_text'> Systems: </h2>
                            <HexStats points={curPilot.mechSkills ? curPilot.mechSkills.systems : 0} skill='sys' />
                        </div>
                        <div className='d-flex flex-column'>
                            <h2 className='pilot_stat_text'> Engineering: </h2>
                            <HexStats points={curPilot.mechSkills ? curPilot.mechSkills.engineering : 0} skill='eng' />
                        </div>
                    </div>
                </div>
                
                    
                </div>
            </div>    
        </div>
    )
}

function HexStats(props) {
    const hexes = [];

    for(let i = 1; i <= 6; i++) {
        if(i <= props.points) hexes.push(<img className='hex_img' src='Other/hex_filled.png' key={`${props.skill}${i}`}/>);
        else hexes.push(<img className='hex_img' src='Other/hex_empty.png' key={`${props.skill}${i}`}/>);
    }

    return (
        <div>
            {hexes}
        </div>
    )
}

function RankImg(props) {
    let rankname = 'L' + (props.level > 12 ? 12 : props.level);

    // Rank images disabled for now
    return;

    return (
        <img className='rank_img' src={`Ranks/${rankname}.png`}/>
    )
}
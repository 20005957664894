import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './mobileheader.css';

export function MobileHeader({version}) {
    return (
        <header>
            <div id='mb_triangle'/>
            <div id='mb_rec1'/>
            <div id='mb_rec2'>
            <h1 id='mb_title'>POF-NETWORK [{ version ? version : 'v0.2.0'}]</h1>
            </div>
            <h1 id='mb_headertext'>AGR-POF 02</h1>
        </header>
    )
}
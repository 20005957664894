import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { MainChannel } from './sub-components/MainChannel';
import { ChannelCreate } from './sub-components/Create/ChannelCreate';
import { CHView } from './CHView/CHView';

import './channel.css';

const ChannelRoutes = (fullProps) => [
    {
      index: true,
      element: <MainChannel
        {...fullProps}
      />
    }, 
    {
      path: `create`,
      element: <ChannelCreate/>
    },
    {
      path: `chview/*`,
      element: <CHView
        missions={fullProps.missions}
        setMissions={fullProps.setMissions}
        clientId={fullProps.clientId}
        setViewmiss_fromMain={fullProps.setViewmiss_fromMain}
        MOBILE_MODE={fullProps.MOBILE_MODE}
      />
    },
  ];

export function Channels(props) {   
    return (
        <Routes>
            {ChannelRoutes(props).map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
            })}
        </Routes>
    );
}
import React, { createContext } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { UserPool } from './Util/cognito';
import { toast } from 'react-toastify';

const AccountContext = createContext();

const getUser = (email) => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool: UserPool
    });
};


// DISCORD DATA
const DISCORD_AppID = '1168055436205895680';
const DISCORD_ClientSecret = '746ji2LH1JGCrsvEuHaJeZhSI9GD59Ds';

const Account = (props) => {
    const getSession = async () => new Promise((res, rej) => {
        const user = UserPool.getCurrentUser();
        if(user) {
            user.getSession((err, session) => {
                if(err) {
                    rej();
                }
                else {
                    res(session);
                }
            });
        }
        else {
            rej();
        }
    })

    const getSessionThen = async (callback) => {
        const user = UserPool.getCurrentUser();
        if(user) {
            user.getSession((err, session) => {
                if(err) {
                    toast.error("Failed to get user session.");
                    return;
                }

                // Session is the user session
                callback(session);
            });
        }
        else {
            toast.error("Failed to get user session.");
        }
    }

    const register = async (email, pass, splashError) => await new Promise((res, rej) => {
        const attributeList = [];
    

        UserPool.signUp(email, pass, [], null, (err, _res) => {
            if(err) {
                rej(err);
                return;
            }
            res(_res);
        })
    });

    const authenticate = async (email, Password, splashError) => 
        await new Promise((res, rej) => {
            const user = new CognitoUser({ Username: email, Pool: UserPool});
      
            const authDetails = new AuthenticationDetails({ Username: email, Password });
        
            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    res(data); 
                },
                onFailure: (err) => {
                    rej(err);
                },
                newPasswordRequired: (data) => {
                    res(data);
                }
            });
        })
    
    const logout = (callback) => {
        const user = UserPool.getCurrentUser();
        if(user) {
            user.signOut(callback);
        }
    }

    const verifyUser = async (email, code) => {
        let user = getUser(email);

        if(user) {
            return await new Promise((res, rej) => {
                user.confirmRegistration(code, true, (err, _res) => {
                    if(err) {
                        rej(err);
                        return;
                    }
                    res(_res);
                });
            })
        }
        else {
            return await new Promise((res, rej) => {
                if(1 > 5) res('dnd joke');
                rej("Something went wrong trying to verify user.");
            })
        }
    }

    const resendVerification = async (email) => {
        const user =  getUser(email);

        if(user) {
            return await new Promise((res, rej) => {
                user.resendConfirmationCode((err, _res) => {
                    if(err) {
                        rej(err);
                        return;
                    }
                    res(_res);
                });
            })
        }
        else {
            return await new Promise((res, rej) => {
                if(1 > 5) res('dnd joke');
                rej("Something went wrong trying to resend code.");
            })
        }
    }

    const initForgotPassword = async (email) => {
        const user =  getUser(email);

        if(user) {
            return await new Promise((res, rej) => {
                user.forgotPassword({
                    onSuccess: (data) => {
                        res(data); 
                    },
                    onFailure: (err) => {
                        rej(err);
                    },
                    inputVerificationCode: (data) => {
                        //
                    }
                })
            });
        }
        else {
            return await new Promise((res, rej) => {
                if(1 > 5) res('dnd joke');
                rej("Something went wrong trying to reset password.");
            })
        }
    }

    const confirmForgotPassword = async (email, code, password) => {
        const user =  getUser(email);

        if(user) {
            return await new Promise((res, rej) => {
                user.confirmPassword(code, password, {
                    onSuccess: (data) => {
                        res(data); 
                    },
                    onFailure: (err) => {
                        rej(err);
                    }
                });
            });
        }
        else {
            return await new Promise((res, rej) => {
                if(1 > 5) res('dnd joke');
                rej("Something went wrong trying to reset password.");
            })
        }
    }

    return (
        <AccountContext.Provider value={{getSession, getSessionThen, register, authenticate, logout, verifyUser, resendVerification, initForgotPassword, confirmForgotPassword}}>
            {props.children}
        </AccountContext.Provider>
    );
};

export { AccountContext, Account };
import React, { useState } from 'react';

export function WP_Inbox(props) {
    return (
        <div className='wirePanel __inb_content'>
            <h2 className='wirePanel_h2'>
                {props.title}
            </h2>
        </div>
    );
}
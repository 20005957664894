import React, { useState, useEffect, useContext, useRef } from 'react';
import { toast } from 'react-toastify';

import { PilotView } from '../../Shared/PilotView';
import { BackButton } from '../../Shared/Backbutton';

import { UpdateHandlerName, UploadPilot, DeletePilot } from '../../../Util/api';
import { AccountContext } from '../../../Account';

import './profile.css';
import './mbprofile.css';

const DISCORD_OAUTH = 'https://discord.com/api/oauth2/authorize?client_id=1168055436205895680&redirect_uri=https%3A%2F%2Fagriafoundation.org%2Fauth%2Fdiscord&response_type=code&scope=identify';

export function Profile({ handler, pilots, SetClientState, backTo, MOBILE_MODE }) {
    const [editHandler, setEditHandler] = useState(false);
    const [editedHandler, setEditedHandler] = useState('');
    const [curHandler, setCurHandler] = useState('UNKNOWN');
    const inputFile = useRef(null);

    const [requestCD, setRequestCD] = useState(false);

    useEffect(() => {
        setEditedHandler(handler);
        setCurHandler(handler);
    }, [handler]);

    const { getSession, getSessionThen } = useContext(AccountContext);

    const requestHandlerNameChange = function(changeName) {
        if(requestCD) return;

        if(changeName === null || changeName === undefined || changeName === null || changeName === '' || changeName.length > 16) {
            toast.error('Invalid handler name');
            return;
        }
        // Regardless of the outcome, just have it be instant
        setCurHandler(changeName);
        setEditHandler(false);

        getSessionThen((session) => {
            setRequestCD(true);
            // This means getting the session worked, continue as normal
            UpdateHandlerName(session.getIdToken().getJwtToken(), changeName).then((data) => {
                toast.success('Handler name successfully updated.');
                SetClientState(data.Attributes);
            }).catch((err) => {
                toast.error("Failed to update handler name. Please try again later.");
            }).finally(() => {
                setRequestCD(false);
            })
        });
    }

    const requestUploadPilot = function(pilotObj) {
        if(requestCD) return;

        getSessionThen((session) => {
            setRequestCD(true);
            // This means getting the session worked, continue as normal
            UploadPilot(session.getIdToken().getJwtToken(), pilotObj).then((data) => {
                SetClientState(data.Attributes);
                toast.success("Pilot uploaded.");
            }).catch((err) => {
                toast.error("Failed to upload pilot file to server." + err.message);
            }).finally(() => {
                setRequestCD(false);
            })
        });
    }

    const requestDeletePilot = function(id) {
        if(requestCD) return;

        getSessionThen((session) => {
            setRequestCD(true);

            // This means getting the session worked, continue as normal
            DeletePilot(session.getIdToken().getJwtToken(), id).then((data) => {
                SetClientState(data.Attributes);
                toast.success("Pilot deleted.");
            }).catch((err) => {
                toast.error("Failed to delete pilot");
            }).finally(() => {
                setRequestCD(false);
            })
        });
    }

    return (
        MOBILE_MODE ? 
        <div className='d-flex justify-content-center'>
            <div className='mb_profile_content'>
                <input
                    type='file'
                    id='mb_upload_file'
                    ref={inputFile}
                    accept='application/json, .json'
                    style={{display: 'none'}}
                    onChange={(event) => {
                        const fileReader = new FileReader();
                        fileReader.readAsText(event.target.files[0], "UTF-8");
                        
                        // You aren't passing a callback, you're setting onLoad to this function
                        fileReader.onload = (e) => {
                            try {
                                requestUploadPilot(JSON.parse(e.target.result));
                            }
                            catch(err) {
                                toast.error("Failed to parse pilot file.");
                            }
                        };
                    }}
                />

                <div id='mb_handler_container' className='d-flex'>
                    <h1>POF-USER: </h1>
                    {
                        editHandler ?
                        <>
                            <form className='d-inline mx-3' onSubmit={(event) => {
                                event.preventDefault();
                                requestHandlerNameChange(editedHandler);
                            }}>
                                <input
                                    type="text"
                                    id='mb_handler_name_input'
                                    value={editedHandler}
                                    onChange={(e) => setEditedHandler(e.target.value)}
                                />
                            </form>
                            <button className='handler_ico_container' onClick={(event) => {
                                event.preventDefault();
                                requestHandlerNameChange(editedHandler);
                            }}>
                                <img className='mb_handler_ico_btn crimg' src={`/Icons/ico_save.svg`} alt='edit handler btn'/>
                            </button>
                            <button className='handler_ico_container' onClick={(event) => {
                                event.preventDefault();
                                setEditHandler(false);
                            }}>
                                <img className='mb_handler_ico_btn crimg' src={`/Icons/ico_cancel.svg`} alt='edit handler btn'/>
                            </button>
                        </>
                        :
                        <>
                            <h1 id='mb_handler_name'>{curHandler}</h1>
                            <button className='handler_ico_container mx-2' onClick={(event) => {
                                event.preventDefault();
                                setEditedHandler(curHandler);
                                setEditHandler(true);
                            }}>
                                <img className='crimg mb_handler_ico_btn' src={`/Icons/ico_pencil.svg`} alt='edit handler btn'/>
                            </button>
                        </>
                    }
                </div>

                <div>
                    <div className='d-flex justify-content-between px-2 py-2'>
                        <h1>Roster: </h1>
                        <button className='mb_pilot_upload_btn crtext' onClick={(event) => {
                            event.preventDefault();
                            inputFile.current.click();
                        }}>{'Upload'}</button>
                    </div>
                    
                    <div className='mb_pilot_pilot_panel'>
                            
                        <div className='d-flex flex-column p-3 m-auto gap-5'>   
                            {pilots ?
                            pilots.map((x, index) => 
                            <div>
                                <PilotView key={`PILOT-${index}`} pilot_ob={x} MOBILE_MODE={MOBILE_MODE}/>
                                <button key={`DLT-${index}`} className='mb_pilot_option_button limit_pilot_buttons crtext' onClick={(event) => {
                                    event.preventDefault();
                                    requestDeletePilot(x.id);
                                }}>{'>> Delete'}</button>
                            </div>)
                            : undefined}
                        </div>
                    </div>
                </div>

            </div>

            
        </div>
        :
        <>
            <div className='profile_content'>
                <BackButton  backTo={backTo}/>
                <input
                    type='file'
                    id='upload_file'
                    ref={inputFile}
                    accept='application/json, .json'
                    style={{display: 'none'}}
                    onChange={(event) => {
                        const fileReader = new FileReader();
                        fileReader.readAsText(event.target.files[0], "UTF-8");
                        
                        // You aren't passing a callback, you're setting onLoad to this function
                        fileReader.onload = (e) => {
                            try {
                                requestUploadPilot(JSON.parse(e.target.result));
                            }
                            catch(err) {
                                toast.error("Failed to parse pilot file.");
                            }
                        };
                    }}
                />

                        

                <div className='d-flex'>
                    <div>
                        <h1 id='handler_container'>POF-USER : 
                            {
                                editHandler ?
                                <>
                                    <form className='d-inline mx-3' onSubmit={(event) => {
                                        event.preventDefault();
                                        requestHandlerNameChange(editedHandler);
                                    }}>
                                        <input
                                            type="text"
                                            id='handler_name_input'
                                            value={editedHandler}
                                            onChange={(e) => setEditedHandler(e.target.value)}
                                        />
                                    </form>
                                    <button className='handler_ico_container' onClick={(event) => {
                                        event.preventDefault();
                                        requestHandlerNameChange(editedHandler);
                                    }}>
                                        <img className='handler_ico_btn crimg' src={`/Icons/ico_save.svg`} alt='edit handler btn'/>
                                    </button>
                                    <button className='handler_ico_container' onClick={(event) => {
                                        event.preventDefault();
                                        setEditHandler(false);
                                    }}>
                                        <img className='handler_ico_btn crimg' src={`/Icons/ico_cancel.svg`} alt='edit handler btn'/>
                                    </button>
                                </>
                                :
                                <>
                                    <span id='handler_name'> {curHandler}</span>
                                    <button className='handler_ico_container' onClick={(event) => {
                                        event.preventDefault();
                                        setEditedHandler(curHandler);
                                        setEditHandler(true);
                                    }}>
                                        <img className='crimg handler_ico_btn' src={`/Icons/ico_pencil.svg`} alt='edit handler btn'/>
                                    </button>
                                </>
                            }
                        </h1>
                    
                        <div className='pilot_option_panel'>
                            <h1>Options:</h1>
                            <button className='pilot_option_button crtext' onClick={(event) => {
                                event.preventDefault();
                                inputFile.current.click();
                            }}>{'>> Upload/Update Pilot'}</button>
                            <button className='pilot_option_button crtext' onClick={(event) => {
                                event.preventDefault();
                                window.location.href = DISCORD_OAUTH;
                            }}>
                                {'>> Link Discord'}
                            </button>
                        </div>
                    </div>

                    <div>
                        <h1 className='px-3 m-auto'>Roster: </h1>
                        <div className='pilot_pilot_panel'>
                            
                            <div className='d-flex flex-column p-3 m-auto gap-5'>   
                                {pilots ?
                                pilots.map((x, index) => 
                                <div>
                                    <PilotView key={`PILOT-${index}`} pilot_ob={x}/>
                                    <button key={`DLT-${index}`} className='pilot_option_button limit_pilot_buttons crtext' onClick={(event) => {
                                        event.preventDefault();
                                        requestDeletePilot(x.id);
                                    }}>{'>> Delete'}</button>
                                </div>)
                                : undefined}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
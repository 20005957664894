import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CreateMission, UpdateMission } from '../../Util/api';

import './createmissionpanel.css';


export function CreateMissionPanel({mode, setDisplay, getSessionThen, channelId, missions, setMissions, baseMission, setMissionData}) {
    const [mName, setMName] = useState(baseMission ? baseMission.name : '');
    const [mDesc, setMDesc] = useState(baseMission ? baseMission.desc : '');
    const [clientName, setClientName] = useState(baseMission ? baseMission.clientName : '');
    const [duration, setDuration] = useState(baseMission ? baseMission.duration : '1 Scene');
    const [createMode, setCreateMode] = useState(mode === 'create');

    const [recLevel, setRecLevel] = useState(baseMission ? baseMission.recLevel : 0);
    const [minCapacity, setMinCapacity] = useState(baseMission ? baseMission.minCapacity : 2);
    const [maxCapacity, setMaxCapacity] = useState(baseMission ? baseMission.maxCapacity : 5);
    const [time, setTime] = useState(null);
    const [requestCD, setRequestCD] = useState(false);

    useEffect(() => {
        if(baseMission && baseMission.timecode && baseMission.timecode > 28800) {
            const d = new Date(0);
            d.setUTCSeconds(baseMission.timecode);
            const dateTimeLocalValue = (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString()).slice(0, -1);
            setTime(dateTimeLocalValue);
        }
    }, [baseMission]);

    const navigate = useNavigate();

    const convertTimeToUNIX = (t) => {
        const staticDate = new Date(t);
        const offset = staticDate.getTimezoneOffset();
        const localTime = new Date(staticDate.getTime() + offset * 60000);
        return Math.floor(localTime.getTime() / 1000);
    }

    const requestCreateMission = () => {
        if(requestCD) return;
        getSessionThen((session) => {
            setRequestCD(true);

            if(createMode) {
                const missionData = {
                    channelId: channelId ? channelId : '',
                    createMissionData: {
                        name: mName,
                        desc: mDesc,
                        clientName,
                        duration,
                        recLevel: parseInt(recLevel),
                        minCapacity: parseInt(minCapacity),
                        maxCapacity: parseInt(maxCapacity),
                        timecode: convertTimeToUNIX(time),
                    },
                };
    
                CreateMission(session.getIdToken().getJwtToken(), missionData).then((data) => {
                    toast.success("Mission created.");
                    setMissions([...missions, data]);
                    navigate('/app/mission/' + data);
                }).catch((err) => {
                    toast.error("Failed to create mission.");
                }).finally(() => {
                    setRequestCD(false);
                });
            }
            else {
                const missionData = {
                    channelId: baseMission.channelId,
                    missionId: baseMission.id,
                    updateMissionData: {
                        name: mName,
                        desc: mDesc,
                        clientName,
                        duration,
                        recLevel: parseInt(recLevel),
                        minCapacity: parseInt(minCapacity),
                        maxCapacity: parseInt(maxCapacity),
                        timecode: convertTimeToUNIX(time),
                    },
                };
    
                UpdateMission(session.getIdToken().getJwtToken(), missionData).then((data) => {
                    toast.success("Mission updated.");
                    setMissionData(data);
                    setDisplay(false);
                }).catch((err) => {
                    toast.error("Failed to update mission.");
                }).finally(() => {
                    setRequestCD(false);
                });
            }
        });
    };

    return (
        <div className='create_mission'>
            <div className='create_mission_content d-flex flex-column justify-content-between p-3'>
                <div className='d-flex'>
                    <div className='create_mission_textcontainer'>
                        <h1>Mission Title: </h1>
                        <input className='create_mission_textinput crtext' type='text' value={mName} onChange={(e) => setMName(e.target.value)}/>
                        <h2 className='mt-3'>Mission Desc:</h2>
                        <textarea className='create_mission_textarea' spellCheck='false' value={mDesc} onChange={(e) => setMDesc(e.target.value)}/>
                    </div>

                    <div className='d-flex flex-column create_mission_misccontainer justify-content-between'>
                        <div className='d-flex flex-column'>
                            <h1>Client</h1>
                            <input className='create_mission_textinput crtext' type='text' value={clientName} onChange={(e) => {
                                setClientName(e.target.value);
                            }}/>
                        </div>

                        <div className='d-flex gap-5 mt-3'>
                            <div className='d-flex flex-column'>
                                <label className='create_mission_label'>Recommended Lvl</label>
                                <input className='create_mission_num crtext' type='text' value={recLevel} onChange={(e) => {
                                    let parsedString = parseInt(e.target.value);
                                    if(isNaN(parsedString)) parsedString = 0;                       
                                    setRecLevel(parsedString);
                                }}/>
                            </div>
                        </div>

                        <div className='d-flex gap-5'>
                            <div className='d-flex flex-column'>
                                <label className='create_mission_label'>Min Capacity</label>
                                <input className='create_mission_num crtext' type='text' value={minCapacity} onChange={(e) => {
                                    let parsedString = parseInt(e.target.value);
                                    if(isNaN(parsedString)) parsedString = 0;
                                    setMinCapacity(parsedString);
                                }}/>
                            </div>
                            <div className='d-flex flex-column'>
                                <label className='create_mission_label'>Max Capacity</label>
                                <input className='create_mission_num crtext' type='text' value={maxCapacity} onChange={(e) => {
                                    let parsedString = parseInt(e.target.value);
                                    if(isNaN(parsedString)) parsedString = 0;
                                    setMaxCapacity(parsedString);
                                }}/>
                            </div>
                        </div>
                        
                        <div className='d-flex flex-column mt-5'>
                            <label className='create_mission_label'>Meeting Time</label>
                            <input
                                className='create_mission_dt crtext'
                                type="datetime-local"
                                id="meeting-time"
                                name="meeting-time"
                                value={(time || '').toString().substring(0, 16)}
                                onChange={(e) => {
                                    if (!e.target['validity'].valid) return;
                                    const dt= e.target['value'] + ':00Z';
                                    setTime(dt);
                                }}
                            />
                        </div>
                    </div>
                </div>       

                <div className='d-flex justify-content-between'>
                    <button
                        className='create_mission_btn crtext'
                        onClick={() => setDisplay(false)}>
                            Cancel
                    </button>
                    <button
                        className='create_mission_btn crtext'
                        onClick={() => requestCreateMission()}>
                            {createMode ? 'Create' : 'Update'}
                    </button>
                </div>
            </div>
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './mobiletoolbar.css';


const ICON_CHANNELS = '/Icons/ico_channels.svg';
const ICON_HOME = '/Icons/ico_home.svg';
const ICON_MAIL = '/Icons/ico_mail_closed.svg';
const ICON_PROFILE = '/Icons/ico_medal.svg';

export function MobileToolbar() {
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    let navigate = useNavigate();

    const controlNavbar = () => {
        if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
        setShow(false); 
        } else { // if scroll up show the navbar
        setShow(true);  
        }

        // remember current page location to use in the next move
        setLastScrollY(window.scrollY); 
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
    
        // cleanup function
        return () => {
           window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY, controlNavbar]);

    // if(!show) return;

    return (
        <div className='d-flex justify-content-center'>
            <div className={`mb_rectangle${show ? '' : '_close'} d-flex flex-column justify-content-center`}>
                <div className='d-flex justify-content-center crimg'>
                    <button className='mb_btn' onClick={(e) => {
                        navigate('/app/channels');
                    }}>
                        <img className='mb_icon' src={ICON_CHANNELS}/>
                    </button>

                    <button className='mb_btn mb_btnborder' onClick={(e) => {
                        navigate('/app');
                    }}>
                        <img className='mb_icon' src={ICON_HOME}/>
                    </button>

                    <button className='mb_btn mb_btnborder' onClick={(e) => {
                        navigate('/app/mail');
                    }}>
                        <img className='mb_icon' src={ICON_MAIL}/>
                    </button>

                    <button className='mb_btn mb_btnborder' onClick={(e) => {
                        navigate('/app/profile');
                    }}>
                        <img className='mb_icon' src={ICON_PROFILE}/>
                    </button>
                </div>
            </div>
        </div>
    )
}
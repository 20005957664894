import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { DISCORD_AUTH } from './discordauth';

const AuthRoutes = (fullProps) => [
    {
        index: true,
        element: <DISCORD_AUTH/>
    },
    {
      path: `discord/*`,
      element: <DISCORD_AUTH/>
    },
  ];

export function AUTH(props) {
    

    return (
        <Routes>
            {AuthRoutes(props).map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
            })}
        </Routes>
    );
}

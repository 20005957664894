import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SplashHeader } from '../../Shared/SplashHeader';
import { SplashFooter } from '../../Shared/SplashFooter';
import { toast } from 'react-toastify';

import './splash.css';


export function Splash(props) {
    const navigate = useNavigate();

    const enterSite = () => {
        navigate('/app');
    }

    return (
        <>
        <div className='mainsplash d-flex flex-column justify-content-between'>
            <SplashHeader
                solid={false}
            />

            <img id='sp_main_img' src='Stock/banner.jpg' />

            <div id='sp_main_text'>
                Your future, on us
            </div>
            
            {props.sessionStatus ? 
            <>
                <AuthBypass
                    enterSite={enterSite}
                />
            </>
            :
            <AuthMenu 
                btnSignIn={props.btnSignIn}
                btnRegister={props.btnRegister}
                btnForgotPassword={props.btnForgotPassword}
                btnSubmitVerificationCode={props.btnSubmitVerificationCode}
                btnNewPass={props.btnNewPass}
                btnResendVerification={props.btnResendVerification}
            />
            }
            
            <div className='org_set'>
            <div id='org_info' className='d-flex flex-column'>
                <InfoPanel
                    MOBILE_MODE={props.MOBILE_MODE}
                    title={'Our Mission, Your Success'}
                    left={true}
                    image={'Stock/agriculture.jpg'}
                    desc={
                        `Collaborating with local leaders, community organizations, and government bodies to identify and deliver what each colony needs.`
                    }
                />
            </div>
            <div id='org_info' className='d-flex flex-column'>
                <InfoPanel
                    MOBILE_MODE={props.MOBILE_MODE}
                    title={'Connecting Deep Space'}
                    left={false}
                    image={'Stock/engineering.jpg'}
                    desc={
                        `Connecting the frontier with the SolarNet. Widely available and extremely secure.`
                    }
                />
            </div>
            <div id='org_info' className='d-flex flex-column'>
                <InfoPanel
                    MOBILE_MODE={props.MOBILE_MODE}
                    title={'A Secure Frontier'}
                    left={true}
                    image={'Stock/soldier.jpg'}
                    desc={
                        `Securing all our partners with trained soldiers and pilots, so they can focus on building a successful community.`
                    }
                />
            </div>
            </div>
            <SplashFooter/>
        </div>
        </>
    );
}

const AuthBypass = (props) => {

    return (
        <div id='auth_menu_bypass' className='mt-5'>
            <div id='auth_container' className='d-flex flex-column justify-content-between'>
                <div className='auth_border auth_b_t d-flex flex-column justify-content-center'>
                    Welcome, Pilot
                </div>
                <button id='enter_site_btn' onClick={(event) => {
                    event.preventDefault();
                    props.enterSite();
                }}>
                    Enter
                </button>
                <div className='auth_border auth_b_b'/>
            </div>
            
        </div>
    );
}

const AuthMenu = (props) => {
    // 0 = Sign in, 1 = Register, 2 = reset email (need email), 3 = reset email (need code), 4 = verification login, 5 = new pass old pass
    const [mode, setMode] = useState(0);
    const [hasError, setError] = useState(false);

    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [code, setCode] = useState('');

    const splashError = (message) => {
        console.log('SPLASH ERR ', message);
        setError(true);
    }

    const switchMode = (num) => {
        setMode(num);
        setConfirmPass('');
        setPass('');
        setEmail('');
        setError(false);
        setCode('');
    }

    

    const handleGreaterForms = function(event) {
        event.preventDefault();
        if(mode === 2) {
            setMode(3);
            props.btnForgotPassword(email);
        }
        if(mode === 3 ) {
            if(pass !== confirmPass) {
                splashError("Passwords must match!");
                return;
            }
            props.btnNewPass(email, code, pass, backToHome);
        }
        if(mode === 4) {
            props.btnSubmitVerificationCode(email, code, backToHome);
        }
    }

    const backToHome = function(message) {
        toast.success(message);
        setMode(0);
    }

    return (
        <div id='auth_menu' className='mt-5'>
            <div id='auth_container' className='d-flex flex-column justify-content-between'>
                <div className='auth_border auth_b_t d-flex flex-column justify-content-center'>
                    Welcome
                </div>
                {mode > 1 ? 
                <form onSubmit={handleGreaterForms}>
                <div className='d-flex flex-column justify-content-center'>
                    {
                        mode === 2 || mode === 4 ? 
                        <>
                        <label className='auth_label'>Email:</label>
                        <input 
                            className='auth_input'
                            type="text" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        </>
                        :
                        undefined
                    }
                    {
                        mode === 3 ? 
                        <>
                            <label className='auth_label'>Verification Code:</label>
                            <input 
                                className='auth_input'
                                type="text" 
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <label  className='auth_label'>Password:</label>
                            <input 
                                className='auth_input'
                                type="password" 
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}
                            />
                            <label className='auth_label'>Confirm Password:</label>
                            <input 
                                className='auth_input'
                                type="password" 
                                value={confirmPass}
                                onChange={(e) => setConfirmPass(e.target.value)}
                            />
                        </>
                        :
                        undefined
                    }
                    {
                        mode === 4 ? 
                        <>
                            <label className='auth_label'>Verification Code:</label>
                            <input 
                                className='auth_input'
                                type="text" 
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </>
                        :
                        undefined
                    }
                    <input
                        className='auth_blue_btn mt-4'
                        type='submit'
                        value='Confirm'
                    />
                    {
                        mode === 4 ? 
                        <span className='auth_signin_txt mt-1'><button className='auth_signin_txt_specific linkbutton' onClick={(event) => {
                            event.preventDefault();
                            props.btnResendVerification(email);
                        }}>Resend Code</button></span>
                        :
                        undefined
                    }
                    <button className='auth_gray_btn mt-1' onClick={(event) => {
                        event.preventDefault();
                        switchMode(0);
                    }}>Go Back</button>
                </div>
                
                </form>
                : 
                <form onSubmit={mode === 0 ? 
                    (event) => {
                        event.preventDefault();
                        props.btnSignIn(email, pass, splashError);
                    } 
                    :
                    (event) => {
                        event.preventDefault();
                        if(pass !== confirmPass) {
                            splashError('Passwords must match!');
                            return;
                        }

                        props.btnRegister(email, pass, splashError, () => {
                            let oldEmail = email;
                            setMode(4);
                            setEmail(email);
                        });
                    }
                    }>
                <div className='d-flex flex-column justify-content-center'>
                    <label className='auth_label'>Email:</label>
                    <input 
                        className='auth_input'
                        type="text" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    
                    <label  className='auth_label'>Password:</label>
                    <input 
                        className='auth_input'
                        type="password" 
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                    />
                    {
                        mode === 1 ? 
                        <>
                        <label className='auth_label'>Confirm Password:</label>
                        <input 
                            className='auth_input'
                            type="password" 
                            value={confirmPass}
                            onChange={(e) => setConfirmPass(e.target.value)}
                        />
                        </>
                        :
                        undefined
                    }
                    {
                        mode === 0 ?
                        <>
                            <input
                                className='auth_blue_btn mt-4'
                                type='submit'
                                value='Sign In'
                            />
                            <button className='auth_gray_btn mt-1' onClick={(event) => {
                                event.preventDefault();
                                switchMode(1);
                            }}>Join Us</button>
                            <span className='auth_signin_txt mt-1'><button className='auth_signin_txt_specific linkbutton' onClick={(event) => {
                                event.preventDefault();
                                switchMode(2);
                            }}>Forgot password?</button></span>
                            <span className='auth_signin_txt mt-1'><button className='auth_signin_txt_specific linkbutton' onClick={(event) => {
                                event.preventDefault();
                                switchMode(4);
                            }}>Use verification code</button></span>
                        </>
                        :
                        <>
                            <input
                                className='auth_blue_btn mt-4'
                                type='submit'
                                value='Apply'
                            />
                            <span className='auth_signin_txt mt-1'>Already a member? <button className='auth_signin_txt_specific linkbutton' onClick={(event) => {
                                event.preventDefault();
                                switchMode(0);
                            }}>Sign in</button></span>
                        </>
                    }
                </div>
                
                </form>}
                <div className='auth_border auth_b_b'/>
            </div>
            
        </div>
    )
}

const InfoPanel = ({left, title, desc, image, MOBILE_MODE}) => {

    return (
        <div className='sp_info_container'>
            <div className={`${MOBILE_MODE ? 'd-flex flex-column-reverse text-center' : (left ? 'd-flex' : 'd-flex flex-row-reverse')} gap-5`}>
                <div className={`sp_info_textbox ${MOBILE_MODE ? 'sp_textbox_mobile' : ''}`}>
                    <h1 className='sp_info_title'>{title}</h1>
                    <p className='sp_info_text'>{desc}</p>
                </div>
                
                <img className={`sp_info_img ${MOBILE_MODE ? 'sp_img_mobile' : ''}`} src={image}/>
            </div>
        </div>
    );
}
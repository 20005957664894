import React from 'react';

export function MailNotif(props) {
    let unreadAmm = props.unreadAmm;

    if(unreadAmm === null || unreadAmm === undefined) unreadAmm = 0;
    let cn = (unreadAmm < 5) ? 'text-warning' : 'text-danger';
    return (
        <>
        {`[`}
            {unreadAmm > 0 ? (<span className={cn}>{unreadAmm}</span>) : (0) }
        {`]`}
        </>
    );
}
import React, { useState } from 'react';
import { MB_ChannelOption } from './ChannelOption';
import { useNavigate } from 'react-router-dom';

export function MBWP_Channels({title, visChannels, ChannelSortType, chosenOp, setChosenOp, setViewChosen, selectOption}) {
    const navigate = useNavigate();

    return (
        <div className='__mb_c_content'>
            <h2 className='__mb_c_h2'>
                {title}
            </h2>

            <div className='d-flex flex-column __mb_c_internal'>
                <div className='__mb_c_tablehead d-flex'>
                    <div className='__mb_c_item_4 mx-1'>Channel</div>
                    <div className='__mb_c_item_2'>Members</div>
                </div>
                <div className='__mb_c_pages d-flex flex-column'>
                    {visChannels ? 
                    (
                        visChannels.length > 0 ? visChannels.map((x) => 
                        <MB_ChannelOption
                            id={x.id}
                            key={x.id}
                            channel={x}
                            chosen={chosenOp}
                            selectOption={selectOption}
                        />)
                        :
                        <h3>No channels...</h3>
                    )
                     : <h3>Loading...</h3>}
                </div>
            </div>
        </div>
    );
}
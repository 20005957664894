import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { JoinChannel } from '../../../../Util/api';

import './channelpreview.css';


const LOREM_IPSUM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;
const DEFAULT_IMG = '/Icons/ico_channels.svg';
const OFF_AFF_AGRIA = '/Other/AgriaLogo.png';
const OFF_AFF_POF = '/Other/POFSymbol.png';
const OFF_AFF_GLBL = 'Icons/ico_globe.svg';

export function ChannelPreview({allowApply, anyJoin, desc, id, inviteOnly, name, imgURL, memberCount, setViewChosen, getSessionThen, channels, setChannels}) {
    const [useCustomImg, setUseCustomImg] = useState(false);
    const [requestCD, setRequestCD] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setUseCustomImg(!!imgURL);
    }, [imgURL])

    return (
        <div className='ch_prv'>
            <div className='p-3 cp_img_wrap'>
                <div className='d-flex justify-content-between'>
                    <h1 className='cp_title selectable'>{name}</h1>
                    <h1>Members: {memberCount}</h1>
                </div>
                
                <img className={`cp_img ${(useCustomImg) ? 'cp_cimg' : 'cp_dimg'}`} src={(useCustomImg) ? imgURL : DEFAULT_IMG} onError={() => {
                    setUseCustomImg(false);
                }}/>
                
                
                <div className='cp_desc selectable'>{desc}</div>
                <div className='d-flex justify-content-between p-3'>
                    <button
                        className='cp_btn crtext'
                        onClick={() => setViewChosen(false)}
                    >Close</button>
                </div>
                
            </div>
            
        </div>
    );
}
import React, { useState } from 'react';

export function WP_ChannelNews(props) {


    return (
        <div className='wirePanel __cnews_content'>
            <h2 className='wirePanel_h2'>
                {props.title}
            </h2>

            <div className='__cnews_internal d-flex flex-column px-1'>

            </div>
        </div>
    );
}
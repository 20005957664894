import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TRUE_DEFAULT = '/app';

export function BackButton({backTo}) {
    const navigate = useNavigate();

    return (
        <button id='backbutton' className='textbutton crtext' onClick={(e) => {
            e.preventDefault();
            navigate(backTo ? backTo : TRUE_DEFAULT);
        }}>{'<< BACK'}</button>
    );
}
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ICON_FILTER = '/Icons/ico_filter.svg';

export function MBCHM_Missions({channelMissions, clientId, missions, setViewmiss_fromMain}) {
    const [viewAvailable, setViewAvailable] = useState(true);
    const [viewCurrent, setViewCurrent] = useState(true);
    const [viewFinished, setViewFinished] = useState(false);
    const [viewableMissions, setViewableMissions] = useState([]);

    const navigate = useNavigate();

    // Calculate viewables again if missions change
    useEffect(() => {
        if(!channelMissions || channelMissions.length === 0 || !clientId) {
            setViewableMissions([]);
            return;
        }

        let rawMissions = [];
        for(let i = 0; i < channelMissions.length; i++) {
            const m = channelMissions[i];
            let add = false;

            if(!add && viewAvailable && !m.complete && !missions.includes(m.id)) {
                add = true;
            }

            if(!add && viewCurrent && missions.includes(m.id) && !m.complete) {
                add = true;
            }

            if(!add && viewFinished && m.complete) {
                add = true;
            }

            if(add) {
                rawMissions.push(m);
            }
        }

        console.log('RAWS: ', rawMissions);

        setViewableMissions(rawMissions);
    }, [viewAvailable, viewCurrent, viewFinished, channelMissions, missions, clientId]);

    return (
        <div className='mb_chm_content'>
            <div className='d-flex'>
                <div className='d-flex flex-column mb_chm_fullcol'>
                    <div className='mb_chm_list_header d-flex'>
                        <div className='mb_chm_item_4 mx-1'>Mission</div>
                        <div className='mb_chm_item_2'>Members</div>
                    </div>
                    <div className='mb_chm_list'>
                        <div className='d-flex flex-column'>
                            {viewableMissions.map((x) => <MissionItem key={`MISS${x.id}`} {...{...x, navigate, missions, setViewmiss_fromMain}}/>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const MissionItem = ({id, name, members, maxCapacity, complete, navigate, missions, setViewmiss_fromMain}) => {
    return (
        <button className={`mb_chm_mission_item crtext d-flex ${complete ? 'mb_chm_mission_item__complete' : missions.includes(id) ? 'mb_chm_mission_item__current' : ''}`} onClick={((e) => {
            e.preventDefault();
            setViewmiss_fromMain(false);
            navigate(`/app/mission/${id}`);
        })}>
            <div className='mb_chm_item_4'>{name}</div>
            <div className='mb_chm_item_2'>{members.length - 1}{maxCapacity && maxCapacity > 0 ? `/${maxCapacity}` : undefined}</div> {/* -1 to account for owner */}
        </button>
    );
}
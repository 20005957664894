import React, { useState } from 'react';

import './channeloption.css';

const OFF_AFF_AGRIA = '/Other/AgriaLogo.png';
const OFF_AFF_POF = '/Other/POFSymbol.png';
const OFF_AFF_GLBL = 'Icons/ico_globe.svg';

const specialChance = 99;

export function ChannelOption({ channel, chosen, selectOption}) {
    const {allowApply, anyJoin, desc, id, inviteOnly, name, memberCount} = channel;

    const idShort = id.substring(0, 4);
    let privacy = 'Invite';
    if(allowApply) privacy = 'Application';
    if(anyJoin) privacy = 'Open Join';
    return (
        <button className={`crtext d-flex chn_o ${chosen === channel ? 'chn_o_chosen' : ''}`} onClick={(e) => {
            e.preventDefault();
            selectOption(channel);
        }}>
            <div className='__c_item_0'/>
            <div className='__c_item_3'>pof{idShort}</div>
            <div className='__c_item_4'>{name}</div>
            <div className='__c_item_3'>{privacy}</div>
            <div className='__c_item_3'>{memberCount}</div>
            <div className='__c_item_2'><img className='__c_ico crimg' src={OFF_AFF_POF} alt='aff_logo'/></div>
        </button>
    );
}

export function MB_ChannelOption({channel, chosen, selectOption}) {
    const {allowApply, anyJoin, desc, id, inviteOnly, name, memberCount} = channel;
    return (
        <button className={`crtext d-flex chn_o ${chosen === channel ? 'chn_o_chosen' : ''}`} onClick={(e) => {
            e.preventDefault();
            selectOption(channel);
        }}>
            <div className='__mb_c_item_4'>{name}</div>
            <div className='__mb_c_item_2 px-2'>{memberCount}</div>
        </button>
    );
}
import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AccountContext } from '../../../Account';
import { CompleteMission, GetMission, JoinMission, LeaveMission } from '../../../Util/api';
import { BackButton } from '../../Shared/Backbutton';
import { WP_MissionDetails } from './WP_MissionDetails';
import { PilotView } from '../../Shared/PilotView';
import { CreateMissionPanel } from '../../Shared/CreateMissionPanel';

import './missionview.css';
import './mbmissionview.css'

const LOREM_IPSUM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
const defaultTime = 28800;

export function MissionView(props) {
    return (
        <Routes>
            <Route key={0} path=":id" element={<MVIEW {...props}/>} />
        </Routes>
    );
}

const MVIEW = function({pilots, SetClientState, clientId, viewmiss_fromMain, MOBILE_MODE}) {
    let navigate = useNavigate();

    const {id} = useParams();
    const { getSessionThen } = useContext(AccountContext);
    const [missionData, setMissionData] = useState(undefined);
    const [timecode, setTimecode] = useState(undefined);
    const [showPilotSelect, setShowPilotSelect] = useState(false);
    const [canManage, setCanManage] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [displayUpdateMission, setDisplayUpdateMission] = useState(false);
    const [leaveMode, setLeaveMode] = useState(false);
    const [mb_mode, setMb_Mode] = useState(0); // 0 = details, 1 = desc
    const [rawDate, setRawDate] = useState(undefined);
    const [viewablePilots, setViewablePilots] = useState([]);

    // These are for the channel!
    const [yourPerms, setYourPerms] = useState({});
    const [ypIsOwner, setypIsOwner] = useState(false);
    const [ypIsAdmin, setypIsAdmin] = useState(false);
    const [ypManageUsers, setypManageUsers] = useState(false);
    const [ypManageMissions, setypManageMissions] = useState(false);
    const [ypCreateMissions, setypCreateMissions] = useState(false);
    const [ypJoinMissions, setypJoinMissions] = useState(false);
    
    // Mobile stuff
    const [MBViewMembers, setMBViewMembers] = useState(false);

    // MISC
    const [viewChannelURL, setViewChannelURL] = useState(undefined);
    const [requestCD, setRequestCD] = useState(false);

    useEffect(() => {
        if(!missionData) return;

        const ps = [];
        
        for(let i = 0; i < missionData.members.length; i++) {
            const m = missionData.members[i];
            if(m.pilot.id) ps.push(m.pilot);
        }

        setViewablePilots(ps);
    }, [missionData])

    useEffect(() => {
        if(!timecode) return;

        if(completed) {
            setRawDate(undefined);
            return;
        }

        const d = new Date(timecode * 1000);
        setRawDate(d);
    }, [timecode, completed]);

    useEffect(() => {
        if(!missionData || !clientId) return;
        setViewChannelURL('/app/channels/chview/' + missionData.channelId);
        setCompleted(missionData.complete);
        resetPerms();
    }, [missionData, clientId])

    const joinMission = (pilotId) => {
        if(requestCD) return;

        getSessionThen((session) => {
            setRequestCD(true);
            const joinData = {
                missionId: missionData.id,
                pilotId
            };

            JoinMission(session.getIdToken().getJwtToken(), joinData).then((data) => {
                setShowPilotSelect(false);

                const md = data.missBody.Attributes;
                const ub = data.userBody.Attributes;
                if(md && Object.keys(md).length > 0) setMissionData(md);
                // if(ub && Object.keys(ub).length > 0) setMissionData(ub);

                toast.success("Mission joined!");
            }).catch((err) => {
                toast.error("Failed to join mission.");
            }).finally(() => {
                setRequestCD(false);
            })
        });
    }

    const leaveMission = (pilotId) => {
        if(requestCD) return;

        getSessionThen((session) => {
            setRequestCD(true);
            const leaveData = {
                missionId: missionData.id,
                pilotId
            };

            LeaveMission(session.getIdToken().getJwtToken(), leaveData).then((data) => {
                setShowPilotSelect(false);
                
                const md = data.missBody.Attributes;
                const ub = data.userBody.Attributes;
                if(md && Object.keys(md).length > 0) setMissionData(md);
                // if(ub && Object.keys(ub).length > 0) setMissionData(ub);

                toast.success("Mission left!");
            }).catch((err) => {
                toast.error("Failed to leave mission.");
            }).finally(() => {
                setRequestCD(false);
            })
        });
    }

    useEffect(() => {
        getSessionThen((session) => {
            GetMission(session.getIdToken().getJwtToken(), id).then((data) => {
                setMissionData(data);
                setTimecode(data.timecode);
            }).catch((err) => {
                toast.error("Failed to get mission data.");
                setMissionData(undefined);
            })
        });
    }, [id])

    useEffect(() => {
        if(!missionData || !clientId) return;

        try {
            const perms = missionData.perms;
            setYourPerms(perms);
        }
        catch {
            setYourPerms({});
        }
    }, [clientId, missionData]);

    useEffect(() => {
        resetPerms();
    }, [yourPerms])

    const resetPerms = () => {
        const ypown = clientId && missionData && clientId === missionData.channelOwner;
        setypIsOwner(ypown);

        const ypadm = yourPerms && !!yourPerms.isAdmin;
        setypIsAdmin(ypadm);
        setypManageUsers(yourPerms && !!yourPerms.manageUsers);

        const ypmng = yourPerms && !!yourPerms.manageMissions;
        setypManageMissions(ypmng);
        setypCreateMissions(yourPerms && !!yourPerms.createMissions);
        setypJoinMissions(yourPerms && !!yourPerms.joinMissions);

        if(!canManage) setCanManage(ypown || ypadm || ypmng || (missionData && missionData.ownerId === clientId))
    }

    return (
        MOBILE_MODE ?
        <div className='d-flex justify-content-center'>
            <div className='mb_mission_view'>
                {
                    missionData ?
                    <>
                    {
                        showPilotSelect ?
                        <PilotSelect {...{missionData, pilots, clientId, setShowPilotSelect, joinMission, leaveMission, leaveMode, MOBILE_MODE }}/>
                        :
                        undefined
                    }

                    {
                        MBViewMembers ?
                        <MB_PilotCheck {...{viewablePilots, setMBViewMembers}}/>
                        :
                        undefined
                    }

                    <h1 className='selectable'>{missionData.name}</h1>
                    <div className='d-flex justify-content-around'>
                        <button className={`mb_mv_panelbtn crtext ${mb_mode === 0 ? 'mb_mv_panelbtn_selected' : ''}`}
                        onClick={() => {
                            setMb_Mode(0);
                        }}
                        >
                            Details
                        </button>
                        <button className={`mb_mv_panelbtn crtext ${mb_mode === 1 ? 'mb_mv_panelbtn_selected' : ''}`}
                        onClick={() => {
                            setMb_Mode(1);
                        }}
                        >
                            Description
                        </button>
                    </div>
                    {
                        mb_mode === 0 ?
                        <>
                        <div className='my-3'>
                            <div className='d-flex justify-content-between'>
                                <label className='mb_extra_label'>Client Name:</label>
                                <label className='mb_extra_value'>{missionData.clientName}</label>
                            </div>
                            
                            <div className='d-flex justify-content-between'>
                                <label className='mb_extra_label'>Capacity:</label>
                                <label className='mb_extra_value'>{`${missionData.members ? missionData.members.length - 1 : 0}/${missionData.maxCapacity}`}</label> {/* The -1 is to account for the owner */}
                            </div>
                            <div className='d-flex justify-content-between'>
                                <label className='mb_extra_label'>Recommended Lvl:</label>
                                <label className='mb_extra_value'>{missionData.recLevel}</label>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <label className='mb_extra_label'>Start Time:</label>
                                <label className={`mb_extra_value ${(timecode <= defaultTime) ? '' : 'mb_smaller_text'}`}>{completed ? '--COMPLETE--' : rawDate ? ((timecode <= defaultTime) ? '--UNKNOWN--' : rawDate.toLocaleString()) : '--'}</label>
                            </div>
                        </div>

                        <div className='d-flex flex-column gap-2'>
                        <button className='__md_join_btn crtext' onClick={() => {
                            navigate(viewChannelURL);
                        }}>
                            View Channel
                        </button>
                        <button className='__md_join_btn crtext' onClick={
                            () => setMBViewMembers(true)
                        }>
                            View Members
                        </button>
                        <button className='__md_join_btn crtext' onClick={() => {
                            setLeaveMode(false);
                            setShowPilotSelect(true);
                            // joinMission('some id goes here');
                        }}>
                            Join Mission
                        </button>
                        <button className='__md_join_btn crtext' onClick={() => {
                            setLeaveMode(true);
                            setShowPilotSelect(true);
                        }}>
                            Quit Mission
                        </button>
                        {
                            canManage ?
                            <>
                            <button className='__md_join_btn_adm crtext'>
                                ADMIN_DELETE_MISSION {'<?>'}
                            </button>
                            {
                                !completed ? 
                                <button className='__md_join_btn_adm crtext' onClick={() => {
                                    if(requestCD) return;

                                    getSessionThen((session) => {
                                        setRequestCD(true);
                                        CompleteMission(session.getIdToken().getJwtToken(), missionData.id).then((data) => {
                                            toast.success("Mission set as completed!");
                                            setCompleted(true);
                                        }).catch((err) => {
                                            toast.error("Failed to set mission as complete.");
                                        }).finally(() => {
                                            setRequestCD(false);
                                        })
                                    });
                                }}>
                                    ADMIN_SET_COMPLETE
                                </button>
                                :
                                undefined
                            }
                            </>
                            :
                            undefined
                        }
                        </div>
                        </>
                        :
                        <div className='mb_mv_desc selectable'>
                            {missionData.desc}
                        </div>
                    }
                    </>
                    :
                    undefined
                }
            </div>
        </div>
         : 

        <div className='mission_view'>
            {displayUpdateMission ? <CreateMissionPanel {...{mode: 'edit', setDisplay: setDisplayUpdateMission, getSessionThen, baseMission: missionData, setMissionData}}/> : undefined}
            <BackButton backTo={viewmiss_fromMain ? '/app' : viewChannelURL}/>
            {
                showPilotSelect ?
                <PilotSelect {...{missionData, pilots, clientId, setShowPilotSelect, joinMission, leaveMission, leaveMode, MOBILE_MODE }}/>
                :
                undefined
            }
            {missionData ?
            <>
            <div className='d-flex'>
                <div className='mv_info_container'>
                    <h1 className='selectable'>{missionData.name}</h1>
                    <div className='mv_desc selectable'>
                        {missionData.desc}
                    </div>
                </div>
                
                <WP_MissionDetails {...{title: '// Details', setDisplayUpdateMission, missionData, timecode, setShowPilotSelect, canManage, ypJoinMissions, viewChannelURL, getSessionThen, completed, setCompleted, setLeaveMode, rawDate, viewablePilots}}/>
            </div>
            </>
            :
            undefined
            }
        </div>
    );
}

const MB_PilotCheck = function({viewablePilots, setMBViewMembers}) {
    let shownPilots = [...viewablePilots];
    return (
        <div className='mb_pilot_check_container p-3'>
            <button className='mb_pilot_check_backbtn' onClick={() => {
                setMBViewMembers(false);
            }}>Back</button>

            <div className='d-flex flex-column gap-3 mb_pilot_scroll'>
                {shownPilots ?
                    shownPilots.map((x, index) => 
                    <div>
                        <PilotView key={`PILOT-${index}`} pilot_ob={x} clickable={false} MOBILE_MODE={true} callback={() => {}}/>
                    </div>)
                : <h2>NO PILOTS</h2>}
            </div>    
        </div>
    )
}

const PilotSelect = function({missionData, pilots, clientId, setShowPilotSelect, joinMission, leaveMission, leaveMode, MOBILE_MODE}) {
    let shownPilots = [...pilots];
    if(leaveMode) {
        const members = missionData.members;
        shownPilots = [];

        for(let i = 0; i < members.length; i++) {
            if(members[i].id === clientId && members[i].pilot && members[i].pilot.mechSkills) shownPilots.push(members[i].pilot);
        }
    }

    return (
        <div className={`mv_pilot_select ${MOBILE_MODE ? 'mb_mv_pilot_select' : ''}`}>
            <div className='p-3 d-flex justify-content-between'>
                <h1 className=''>{`Select Pilot ${leaveMode ? 'to remove' : 'to add'}:`} </h1>
                <button className='mv_cancel_pilot_select my-auto crtext' onClick={(e) => {
                    e.preventDefault();
                    setShowPilotSelect(false);
                }}>
                    Cancel
                </button>
            </div>
            
            <div className='d-flex flex-column p-3 m-auto gap-5 mv_pilot_scroll'>   
                {shownPilots ?
                shownPilots.map((x, index) => 
                <div>
                    <PilotView key={`PILOT-${index}`} pilot_ob={x} clickable={true} MOBILE_MODE={MOBILE_MODE} callback={(p) => {
                        if(leaveMode) leaveMission(p.id);
                        else joinMission(p.id);
                    }}/>
                </div>)
                : <h2>NO PILOTS</h2>}
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { PilotView } from '../../Shared/PilotView';
import { CompleteMission } from '../../../Util/api';

const defaultTime = 28800;

export function WP_MissionDetails({ title, setDisplayUpdateMission, missionData, timecode, setShowPilotSelect, inMission, canManage, ypJoinMissions, viewChannelURL, getSessionThen, completed, setCompleted, setLeaveMode, rawDate, viewablePilots}) {
const { clientName, maxCapacity, recLevel, members } = missionData;
    const [viewMembers, setViewMembers] = useState(false);
    
    const [requestCD, setRequestCD] = useState(false);

    const navigate = useNavigate();

    

    const joinMission = () => {
        setLeaveMode(false);
        setShowPilotSelect(true);
    }

    const leaveMission = () => {
        setLeaveMode(true);
        setShowPilotSelect(true);
    }

    const editMission = () => {
        setDisplayUpdateMission(true);
    }

    const completeMission = () => {
        if(requestCD) return;

        getSessionThen((session) => {
            setRequestCD(true);
            CompleteMission(session.getIdToken().getJwtToken(), missionData.id).then((data) => {
                toast.success("Mission set as completed!");
                setCompleted(true);
            }).catch((err) => {
                toast.error("Failed to set mission as complete.");
            }).finally(() => {
                setRequestCD(false);
            })
        });
    }

    return (
        <div className={`wirePanel __md_content ${viewMembers ? '__md_widen' : ''}`}>
            <div className='d-flex'>
                <h2 className='wirePanel_h2'>{title}</h2>
                <label></label>
            </div>
            {
                viewMembers ?
                <div  className='__md_internal_members px-3 d-flex flex-column'>
                    <button className='__md_back_btn crtext' onClick={() => setViewMembers(false)}>
                        Back
                    </button>
                    <div className='__md_pilot_container'>
                        {
                            viewablePilots.length > 0 ?
                            <div className='d-flex flex-column gap-5'>
                                {viewablePilots.map((x, index) => <PilotView  key={`PILOT-${index}`} pilot_ob={x}  />)}
                            </div>
                            :
                            <h2>No members...</h2>
                        }
                    </div>
                </div>
                :
                <div className='__md_internal px-3 d-flex flex-column justify-content-between'>
                    <div className='__md_subcon'>
                        <div className='d-flex justify-content-between'>
                            <label className='md_extra_label'>Client Name:</label>
                            <label className='md_extra_value'>{clientName}</label>
                        </div>
                        
                        <div className='d-flex justify-content-between'>
                            <label className='md_extra_label'>Capacity:</label>
                            <label className='md_extra_value'>{`${members ? members.length - 1 : 0}/${maxCapacity}`}</label> {/* The -1 is to account for the owner */}
                        </div>
                        <div className='d-flex justify-content-between'>
                            <label className='md_extra_label'>Recommended Lvl:</label>
                            <label className='md_extra_value'>{recLevel}</label>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <label className='md_extra_label'>Start Time:</label>
                            <label className={`md_extra_value ${(timecode <= defaultTime) ? '' : 'md_smaller_text'}`}>{completed ? '--COMPLETE--' : rawDate ? ((timecode <= defaultTime) ? '--UNKNOWN--' : rawDate.toLocaleString()) : '--'}</label>
                        </div>
                    </div>

                    <div className='d-flex flex-column gap-2'>
                    <button className='__md_join_btn crtext' onClick={() => {
                        navigate(viewChannelURL);
                    }}>
                        View Channel
                    </button>
                    <button className='__md_join_btn crtext' onClick={() => setViewMembers(true)}>
                        View Members
                    </button>
                    <button className='__md_join_btn crtext' onClick={joinMission}>
                        Join Mission
                    </button>
                    <button className='__md_join_btn crtext' onClick={leaveMission}>
                        Quit Mission
                    </button>
                    {
                        canManage ?
                        <>
                        <button className='__md_join_btn_adm crtext'>
                            ADMIN_DELETE_MISSION {'<?>'}
                        </button>
                        <button className='__md_join_btn_adm crtext' onClick={editMission}>
                            ADMIN_EDIT_MISSION
                        </button>
                        {
                            !completed ? 
                            <button className='__md_join_btn_adm crtext' onClick={completeMission}>
                                ADMIN_SET_COMPLETE
                            </button>
                            :
                            undefined
                        }
                        </>
                        :
                        undefined
                    }
                    </div>
                </div>
            }
        </div>
    );
}
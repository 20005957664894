import React, { useState, useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { POFApp } from './components/Pages/POFApp';
import { Splash } from "./components/Pages/Splash/Splash";
import { SP_About } from './components/Pages/SP_About/SP_About';

import { UserPool } from './Util/cognito';
import { AccountContext, Account } from './Account';
import { AUTH } from './components/Pages/Auth/AUTH';

import './custom.css';


const AppRoutes = function (UserPool, btnSignIn, btnRegister, sessionStatus, btnForgotPassword, btnSubmitVerificationCode, btnNewPass, btnResendVerification, curWidth, MOBILE_MODE) {
  return [
    {
      index: true,
      element: <Splash 
      btnSignIn={btnSignIn}
      btnRegister={btnRegister}
      sessionStatus={sessionStatus}
      btnForgotPassword={btnForgotPassword}
      btnSubmitVerificationCode={btnSubmitVerificationCode}
      btnNewPass={btnNewPass}
      btnResendVerification={btnResendVerification}
      curWidth={curWidth}
      MOBILE_MODE={MOBILE_MODE}
      />
    },
    {
      path: 'about',
      element: <SP_About
        curWidth={curWidth}
        MOBILE_MODE={MOBILE_MODE}
      />
    },
    {
      path: 'auth/*',
      element: <AUTH
        UserPool={UserPool}
        curWidth={curWidth}
        MOBILE_MODE={MOBILE_MODE}
      />
    },
    {
      path: 'app/*',
      element: <POFApp
      version={'0.5.0'}
      UserPool={UserPool}
      curWidth={curWidth}
      MOBILE_MODE={MOBILE_MODE}
      />
    },
  ]
};

const WIDTH_LIMIT = 900;

export function App() {
  const navigate = useNavigate();
  const [cUser, setCUser] = useState(null);
  const [sessionStatus, setSessionStatus] = useState(false);
  const [curWidth, setCurWidth] = useState(window.innerWidth);
  const [MOBILE_MODE, setMOBILE_MODE] = useState(false);

  useEffect(() => {
    const updateHeight = () => {
      setCurWidth(window.innerWidth);
      if(window.innerWidth < WIDTH_LIMIT) setMOBILE_MODE(true);
      else setMOBILE_MODE(false);
    }
    window.addEventListener('resize', updateHeight);

    return (() => {
      window.removeEventListener('resize', updateHeight);
    });
  }, [curWidth])

  useEffect(() => {
    setCurWidth(window.innerWidth);
    if(window.innerWidth < WIDTH_LIMIT) setMOBILE_MODE(true);
    else setMOBILE_MODE(false);
  }, []);
  
  const { register, authenticate, getSession, verifyUser, resendVerification, initForgotPassword, confirmForgotPassword } = useContext(AccountContext);

  // Get Session
  useEffect(() => {
    getSession().then(session => {
      setSessionStatus(true);

      // Get user data
    }).catch(() => {
      setSessionStatus(false);
    })
  }, []);

  const btnSignIn = (Email, Password, splashError) => {
     authenticate(Email, Password).then(data => {
      toast.success("Welcome back.");
      setSessionStatus(true);
      navigate('/app');
     })
     .catch(err => {
      toast.error("Failed to login.");
      splashError(err);
     })
  };

  const btnForgotPassword = (email) => {
    initForgotPassword(email).then(res => {
      toast.success("Password reset confirmation sent.");
    }).catch((err) => {
      toast.error("Something went wrong trying to reset your password.");
    });
  }

  const btnSubmitVerificationCode = (email, code, backToHome) => {
    verifyUser(email, code).then(res => {
      backToHome("Verified account. Please log in.");
    }).catch((err) => {
      toast.error("Failed to verify account.");
    });
  }

  const btnNewPass = (email, code, password, backToHome) => {
    confirmForgotPassword(email, code, password).then(res => {
      backToHome("Password changed.");
    }).catch(err => {
      toast.error("Unable to change password.");
    })
  }

  const btnRegister = (email, pass, splashError, viewVerify) => {
    register(email, pass, splashError).then(res => {
      toast.success("A verification code has been sent to: ", email);
      viewVerify();
    }).catch((err) => {
      toast.error("Unable to register at this time. Please try again later.");
    })
  }

  const btnResendVerification = (email) => {
    resendVerification(email).then(res => {
      toast.success("Your verification code has been resent to: ", email);
    }).catch((err) => {
      toast.error("Unable to resend your verification code.");
    })
  }


  return (
    <>
      <Routes>
        {AppRoutes(UserPool, btnSignIn, btnRegister, sessionStatus, btnForgotPassword, btnSubmitVerificationCode, btnNewPass, btnResendVerification, curWidth, MOBILE_MODE).map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element}/>;
        })}
      </Routes>
      <ToastContainer/>
    </>
      
  );
}

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { WP_Missions } from './WP_Missions';
import { WP_News } from './WP_News';
import { MailNotif } from '../../Shared/MailNotif';
import { AccountContext } from '../../../Account';
import { MBWP_Missions } from './MBWP_MIssions';
import { MBWP_News } from './MBWP_News';

import './home.css';
import './homem.css';

export function Home({homeMissions, clientId, missions, setViewmiss_fromMain, MOBILE_MODE, version}) {
  let navigate = useNavigate();
  const [unreadAmm, setUnreadAmm] = useState(0);

  const { logout } = useContext(AccountContext);
  
  if(MOBILE_MODE)
    return (
      <div className='d-flex justify-content-center'>
        <div className='mobile_home'>
            <div className='d-flex flex-column'>
              <div className='d-flex justify-content-center'>
                <MBWP_Missions {...{title: '// Mission Feed', homeMissions, clientId, missions, setViewmiss_fromMain, setViewmiss_fromMain}}/>
              </div>
              <div className='d-flex justify-content-center my-3'>
                <MBWP_News {...{title: '// News'}}/>
              </div>
            </div>
          </div>
      </div>
    )
  else
    return (
        <div className='d-flex flex-column'>
          <div className='home_content d-flex'>
            <div className='d-flex flex-column'>
              <div className='home_menu d-flex flex-column'>
                <h2 className='mb-5 forcescale_title'>// Menu</h2>
                <button className='textbutton crtext' onClick={() => {
                  navigate('mail');
                }}>{' >> Mail '} <MailNotif unreadAmm={unreadAmm}/> </button>
                <button className='textbutton crtext' onClick={() => {
                  navigate('channels');
                }}>{' >> Channels'}</button>
                <button className='textbutton crtext' onClick={() => {
                  navigate('profile');
                }}>{' >> Profile'}</button>
                
                <button className='textbutton crtext' onClick={() => {
                  navigate('about');
                }}>{' >> About'}</button>
                <button className='textbutton crtext' onClick={() => {
                  logout(() => {
                    navigate('/');
                  })
                }}>{' >> Sign Out'}</button>
              </div>
            </div>
            
            <WP_Missions {...{title: '// Mission Feed', homeMissions, clientId, missions, setViewmiss_fromMain, setViewmiss_fromMain}}/>

            <div className='d-flex flex-column'>
              <div className='newsgraphic_container'>
                <img className='newsgraphic' src='/Icons/ico_globe.svg' alt='News Graphic'/>
              </div>
              <WP_News title={'// News'}/>
            </div>
          </div>
        </div>
    );
}

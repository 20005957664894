import React, { useState } from 'react';
import { BackButton } from '../../Shared/Backbutton';

import './about.css';


export function About(props) {

    return (
        <div className='d-flex flex-column'>
            <BackButton/>
            <div className='about_content d-flex justify-content-center'>
                <div>
                    <div id='header_container'>
                        <header className='about_header selectable'>
                            "Theres more to the world than just that closet you're in." -Yorozu, the Wall
                        </header>
                    </div>
                    
                    <img className='crimg' id='header_img' src='/Other/WingsOfFortune.png' alt='POF Logo'/>

                    <div id='about_text_container'>
                        <p className='about_text selectable'>
                            {
                                `The POF Network is an open network for all mercenaries. All data on our networks are secured in the Agria Foundation's SolarNet servers, safe ` + 
                                `from even the Union's prying eyes. Clients are allowed to post and take any contracts that do not directly interfere with the Agria Foundation's ` + 
                                `interests. The Open Mercenary Network contains listings for all public or recruiting mercenary channels. Private channels are available for more selective ` + 
                                `groups, in which case you may join by recieving an invite code from an authorized member of the channel.`
                            }
                        </p>

                        <p className='about_text selectable'>
                            Mercenaries are the guiding force of the frontiers. Fly swift, pilots.
                        </p>

                        <p className='about_text mt-5 selectable'>
                            Chief Operator: Arcana <br/>
                            Chief Engineer: Radiant
                        </p>
                    </div>  

                </div>
            </div>
        </div>
    );
}

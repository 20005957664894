import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function HeaderGraphic() {
    return (
        <header>
            <div id='rectangle1'/>
            <div id='triangle'/>
            <div id='rectangle2'/>
            <h1 id='headertext'>AGR-POF 02</h1>
        </header>
    )
}
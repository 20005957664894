import React, { useState } from 'react';
import { SplashHeader } from '../../Shared/SplashHeader';
import { SplashFooter } from '../../Shared/SplashFooter';

import './sp_about.css';


export function SP_About(props) {

    return (
        <>
        <SplashHeader
            solid={true}
        />
        <div className='sp_about d-flex flex-column'>
                <div className='sp_ab_textcontent d-flex flex-column'>
                    <div className='sp_ab_title mt-5'>ABOUT</div>
                    <p>
                        This is a fictitious website with fictitious content based on the Lancer TTRPG by <a href="https://massifpress.com/">Massif Press</a>.
                        The purpose of this website is to be an immersive mission hub for our games and serves no business purpose.
                    </p>
                </div>
                <SplashFooter/>
        </div>
        
        </>
        
    );
}

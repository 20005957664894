import React, { useContext, useState, useEffect, searchParams } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { WP_Info } from './WP_Info';
import { WP_Channels } from './WP_Channels';
import { BackButton } from '../../../Shared/Backbutton';
import { ChannelPreview } from './ChannelPreview';
import { AccountContext } from '../../../../Account';
import { GetChannels, JoinChannel } from '../../../../Util/api';
import { MBWP_Channels } from './MBWP_Channels';
import { MBP_Filters } from './MBP_Filters';
import { MBP_ChannelPreview } from './MBP_ChannelPreview';

import '../mbchannel.css';

const ChannelSortType = [
    'Members',
    'Alphabetical',
    'Creation',
]

export function MainChannel(props) {
    const navigate = useNavigate();
    const [visChannels, setVisChannels] = useState(undefined);
    const [chosenOp, setChosenOp] = useState(undefined);
    const [viewChosen, setViewChosen] = useState(false);
    const [viewKeyJoin, setViewKeyJoin] = useState(false);
    const [viewFilters, setViewFilters] = useState(false);
    const [invKey, setInvKey] = useState('');

    const [requestCD, setRequestCD] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams(false);
    const [viewPublic, setViewPublic] = useState(false);
    const [sortType, setSortType] = useState(ChannelSortType[0]);
    const [reverseSort, setReverseSort] = useState(false);
    const [textQuery, setTextQuery] = useState('');

    // Sub filters
    const [viewAnyJoin, setViewAnyJoin] = useState(true);
    const [viewInviteOnly, setViewInviteOnly] = useState(false);

    const { getSessionThen } = useContext(AccountContext);

    const combineParams = () => { return {
        viewPublic,
        sortType,
        reverseSort,
        viewAnyJoin,
        viewInviteOnly,
        textQuery
    }}

    const setParamFromValues = () => {
        const combinedParams = combineParams();
        setSearchParams(combinedParams);
        return combinedParams;
    }

    const selectOption = function(op) {
        if(props.channels.includes(op.id)) {
            navigate('/app/channels/chview/' + op.id);
        }
        else {
            setChosenOp(op);
            setViewChosen(true);
        }
    }

    useEffect(() => {
        // If no search params, just search as normal?
        if(searchParams.size === 0) {
            StartSearch({});
        }
        else {
            // Send state to be equal to params
            const _viewPublic = searchParams.get('viewPublic') === 'true';
            setViewPublic(_viewPublic);

            let _sortType = searchParams.get('sortType');
            _sortType = _sortType ? _sortType : ChannelSortType[0];
            setSortType(_sortType);

            const _reverseSort = searchParams.get('reverseSort') === 'true';
            setReverseSort(_reverseSort);

            let _textQuery = searchParams.get('textQuery');
            _textQuery = _textQuery ? _textQuery : '';
            setTextQuery(_textQuery);

            // Sub filters
            const _viewAnyJoin = searchParams.get('viewAnyJoin') === 'true';
            const _viewInviteOnly = searchParams.get('viewInviteOnly') === 'true';
            setViewAnyJoin(_viewAnyJoin);
            setViewInviteOnly(_viewInviteOnly);
            
            const combinedParams = {
                viewPublic: _viewPublic,
                sortType: _sortType,
                reverseSort: _reverseSort,
                viewAnyJoin: _viewAnyJoin,
                viewInviteOnly: _viewInviteOnly,
                textQuery: _textQuery
            };

            StartSearch(combinedParams);
        }
    }, [])

    const StartSearch = (searchParams) => {
        if(requestCD) return;

        getSessionThen((session) => {
            setRequestCD(true);

            GetChannels(session.getIdToken().getJwtToken(), searchParams).then((data) => {
                setVisChannels(data);
                setViewFilters(false);
            }).catch((err) => {
                toast.error("Error retrieving channel data.");
            }).finally(() => {
                setRequestCD(false);
            })
        })
    }

    return (
    props.MOBILE_MODE ?
        <div className='d-flex justify-content-center'>
            {viewChosen && chosenOp ? <MBP_ChannelPreview {...{...chosenOp, setViewChosen, getSessionThen, channels: props.channels, setChannels: props.setChannels}}/> : undefined}
            
            { viewFilters ? <MBP_Filters {...{title: '// Filters', visChannels, setVisChannels, ChannelSortType, setViewFilters, ChannelSortType, setViewFilters, viewPublic, sortType, setReverseSort, reverseSort, viewAnyJoin, viewInviteOnly, textQuery, setParamFromValues, setSearchParams, setViewPublic, setViewAnyJoin, setViewInviteOnly, setSortType, setTextQuery, StartSearch}}/> : undefined}

            <div className='__mb_c_view'>
                <div className='d-flex justify-content-center my-3'>
                    <button className='__mb_c_filterbtn crtext' onClick={() => {
                        setViewFilters(!viewFilters);
                    }}>
                        Filter Search
                    </button>
                </div>    

                <div className='d-flex justify-content-center'>
                    <MBWP_Channels {...{title: '// Mercenary Channels', visChannels, ChannelSortType, chosenOp, setChosenOp, setViewChosen, selectOption}}/>
                </div>

                <div className='d-flex justify-content-center my-3'>
                {
                    viewKeyJoin ?
                    <div className='d-flex gap-2 px-4'>
                        <label className='my-auto'>KEY: </label>
                        <input className='__mb_c_key_input crtext' type='text' value={invKey} onChange={(e) => {setInvKey(e.target.value)}}/>
                        <button className='__mb_c_key_btn crtext' onClick={(event) => {
                            event.preventDefault();
                            if(requestCD) return;
                                    
                            getSessionThen((session) => {
                                setRequestCD(true);

                                const joinData = {
                                    id: undefined,
                                    inviteKey: invKey,
                                };

                                JoinChannel(session.getIdToken().getJwtToken(), joinData).then((data) => {
                                    navigate('/app/channels/chview/' + data);
                                    toast.success("Channel joined.");
                                }).catch((err) => {
                                    toast.error("Failed to join channel.");
                                }).finally(() => {
                                    setRequestCD(false);
                                })
                            });
                        }}>
                            {'JOIN'}
                        </button>
                    </div>
                    :
                    <button className='__mb_c_joinbtn crtext' onClick={(event) => {
                        event.preventDefault();
                        setViewKeyJoin(true);
                    }}>
                        {' >> Join With Key'}
                    </button>
                }
                </div>
            </div>
        </div>
    :
      <div className='channel_content d-flex'>
          <BackButton/>

          {viewChosen && chosenOp ? <ChannelPreview {...{...chosenOp, setViewChosen, getSessionThen, channels: props.channels, setChannels: props.setChannels}}/> : undefined}
          

          <div className='d-flex flex-column'>
              <WP_Info
                  {...{title: '// Filters', visChannels, setVisChannels, ChannelSortType, viewPublic, sortType, setReverseSort, reverseSort, viewAnyJoin, viewInviteOnly, textQuery, setParamFromValues, setSearchParams, setViewPublic, setViewAnyJoin, setViewInviteOnly, setSortType, setTextQuery, StartSearch}}
              />
              <button className='textbutton crtext px-4 w-100' onClick={(event) => {
                event.preventDefault();
                navigate('create');
              }}>
                  {' >> Create Mercenary Channel'}
              </button>
              {
                viewKeyJoin ?
                <div className='d-flex gap-2 px-4'>
                    <label className='my-auto'>KEY: </label>
                    <input className='channel_key_input crtext' type='text' value={invKey} onChange={(e) => {setInvKey(e.target.value)}}/>
                    <button className='channel_key_btn crtext' onClick={(event) => {
                        event.preventDefault();
                        if(requestCD) return;
                            
                        getSessionThen((session) => {
                            setRequestCD(true);

                            const joinData = {
                                id: undefined,
                                inviteKey: invKey,
                            };

                            JoinChannel(session.getIdToken().getJwtToken(), joinData).then((data) => {
                                navigate('/app/channels/chview/' + data);
                                toast.success("Channel joined.");
                            }).catch((err) => {
                                toast.error("Failed to join channel.");
                            }).finally(() => {
                                setRequestCD(false);
                            })
                        });
                    }}>
                        {'JOIN'}
                    </button>
                </div>
                :
                <button className='textbutton crtext px-4 w-100' onClick={(event) => {
                    event.preventDefault();
                    setViewKeyJoin(true);
                  }}>
                      {' >> Join With Key'}
                </button>
              }
              
          </div>
           
          <WP_Channels {...{title: '// Mercenary Channels', visChannels, ChannelSortType, chosenOp, setChosenOp, setViewChosen, selectOption}}/>
      </div>
    );
}